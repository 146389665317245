<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-app-bar
    :height="60"
    app
    class="d-print-none app-bar"
    color="bar"
    dark
    elevation="0"
    flat
    tile
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-btn
      v-if="showDrawerIcon"
      :x-large="AppIsSmall"
      icon
      @click.stop="drawer = !drawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <!--<v-avatar-->
    <!--  v-if="!AppIsSmall"-->
    <!--  color="white"-->
    <!--&gt;-->
    <!--  <v-img-->
    <!--    :src="AppIcon"-->
    <!--    :aspect-ratio="1"-->
    <!--  />-->
    <!--  &lt;!&ndash;<router-link&ndash;&gt;-->
    <!--  &lt;!&ndash;  :to="route(APP_ROUTES.homePage)"&ndash;&gt;-->
    <!--  &lt;!&ndash;&gt;&ndash;&gt;-->
    <!--  &lt;!&ndash;</router-link>&ndash;&gt;-->
    <!--</v-avatar>-->

    <!--
      <v-avatar
        v-if="!AppIsSmall"
        :color="logoColor"
      >
        <v-img
          :src="AppLogo"
          class="pointer"
          contain
          @fclick="goToHome"
        />
      </v-avatar>
      -->
    <v-toolbar-title
      v-show="!AppIsSmall"
      class="px-1 pointer pe-sm-5"
      @click="goToHome"
    >
      {{ AppName }}
    </v-toolbar-title>
    <v-spacer />
    <!--<template v-if="!AppIsSmall">-->
    <v-spacer />
    <v-col
      class="pa-0"
      cols="auto"
    >
      <app-notifications-menu v-if="authUser" />
      <app-language-menu />
      <v-btn
        v-if="authUser"
        icon
        @click.stop="logout"
      >
        <v-icon>logout</v-icon>
      </v-btn>
    </v-col>
    <!--</template>-->
  </v-app-bar>
</template>

<script>

import SearchBar from '@components/base/app/SearchBar'

export default {
  name: 'Bar',
  components: { SearchBar },
  props: {
    navigation: {
      type: Boolean,
      default: () => undefined
    }
  },
  computed: {
    showDrawerIcon () {
      return !0
      // return this.AppIsSmall
    },
    drawer: {
      get () {
        return this.navigation
      },
      set (v) {
        this.$emit('update:navigation', Boolean(v))
      }
    }
  },
  methods: {
    logout () {
      this.confirmMessage(this.$t('messages.sure_logout'), async () => {
        await this.apiService.auth.logout({ push_token: window.push_token || null }).finally(() => this.logoutUser(true))
      }, () => {
      })
    },
    goToHome () {
      // const name = !this.authUser ? this.APP_ROUTES.homePage : this.APP_ROUTES.user.home
      const name = this.APP_ROUTES.homePage
      this.navigate(name)
    }
  }
}
</script>
<style>
.app-bar .v-toolbar__content {
  /*padding-left: 0 !important;*/
  /*padding-right: 0 !important;*/
}
</style>
