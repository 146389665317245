<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <dialog-modal
    v-model="modal"
    max-width="960"
    no-click-animation
    persistent
    scrollable
  >
    <v-card :loading="loadingData">
      <v-card-title>
        {{ $t('reservationPage.reservations_date', { date }) }}&nbsp;<span class="success--text">({{ items.length }})</span>
        <v-spacer />
        <v-btn
          :disabled="loadingData"
          icon
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slide-fade-transition>
          <v-container
            v-if="!loadingData"
            fluid
          >
            <v-row dense>
              <v-col
                v-for="(e,i) in items"
                :key="i.toString()"
                class="pointer"
                cols="12"
                sm="6"
              >
                <reservation-sheet
                  :color="e.event_color"
                  :item="e"
                  :value="e.event_name"
                  @click="$emit('click:reservation',{item:e})"
                />
              </v-col>
            </v-row>
          </v-container>
        </slide-fade-transition>
      </v-card-text>
      <v-card-actions>
        <!--<app-btn-->
        <!--  :disabled="invalid"-->
        <!--  :loading="loadingData"-->
        <!--  color="success"-->
        <!--  @click="submit"-->
        <!--&gt;-->
        <!--  {{ $t('reservationPage.complete') }}-->
        <!--</app-btn>-->
      </v-card-actions>
    </v-card>
  </dialog-modal>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import { GetHeadersMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'
import ProgressLinear from '@components/base/app/ProgressLinear'
import ReservationSheet from '@components/reservation/ReservationSheet'

export default {
  name: 'ReservationsDialog',
  components: { ReservationSheet, ProgressLinear, SlideFadeTransition, DialogModal },
  mixins: [GetHeadersMixin],
  props: {
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modal: !1,
      loadingData: !1,
      date: undefined,
      items: []
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        v === !1 && this.$emit('close', {})
        if (v === !0) {
          this.fetchItems()
          this.$emit('open', {})
        }
      }
    },
    loadingData: {
      deep: !0,
      handler (v) {
        this.$emit('update:loading', v)
      }
    }
  },
  mounted () {
  },
  methods: {
    openDialog (date) {
      this.date = date
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.date = undefined
        this.items = []
      })
    },
    async fetchItems () {
      if (this.loadingData) return
      this.loadingData = !0
      try {
        const { _data } = await this.apiService.reservation.calendar({ date: this.date })
        this.items = _data || []
      } catch ({ _message }) {
        _message && this.alertError(_message)
      } finally {
        this.loadingData = !1
      }
    }
  }
}
</script>
