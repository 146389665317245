/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Customer')
export default {
  ...Stub(url()),
  statementUrl: (id, ...args) => axios.get(url(`${id}/StatementUrl`), ...args)
}
