/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  add_payment: 'Add Payment',
  confirm: 'Confirm Reservation',
  complete: 'Complete Reservation',
  details: 'Reservation Details',
  payments: 'Payments',
  contract: 'Contract',
  update_date: 'Edit Reservation date',
  contract_signing: 'Contract Signing',
  contract_signing_for_apps: 'Contract Signing For Apps',
  link_copied: 'Link copied',
  open_whatsapp: 'Open Whatsapp Web',
  add_payment_reservation: 'Add /Withdraw Payment',
  reservations_date: 'Reservations {date}'
}
