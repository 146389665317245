<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-row>
      <app-date-picker
        v-model="form.from_date"
        cols="12"
        name="from_date"
        required
        sm="6"
      />
      <app-date-picker
        v-model="form.to_date"
        clearable
        cols="12"
        name="to_date"
        sm="6"
      />
      <!--<app-customers-->
      <!--  v-model="form.customer_id"-->
      <!--  cols="12"-->
      <!--  item-text="name_and_mobile"-->
      <!--  name="customer_id"-->
      <!--  required-->
      <!--  sm="6"-->
      <!--/>-->
    </v-row>
    <v-row align="center">
      <app-users
        v-if="isAdminUser"
        v-model="form.user_id"
        :hint="$t('hints.reservation_user_id')"
        cols="12"
        name="user_id"
        persistent-hint
        required
        sm="6"
      />
      <search-on-customer
        v-model="form.customer_id"
        :customer-name.sync="form.customer_id_to_string"
        :errors.sync="errors"
        search-option="name_and_mobile"
        @customer="customerInput"
        @remove-customer="removeCustomer"
      />
    </v-row>
    <v-row>
      <app-text-input
        v-model="form.name"
        :hint="parseAttribute('tenant_name')"
        clearable
        cols="12"
        name="name"
        persistent-hint
        required
        sm="6"
      />
      <app-number-input
        v-model="form.mobile"
        :hint="$t('hints.tenant_mobile')"
        clearable
        cols="12"
        name="mobile"
        persistent-hint
        required
        rules="mobile"
        sm="6"
      />
      <app-text-input
        v-model="form.other_mobile"
        :hint="$t('hints.tenant_mobile')"
        clearable
        cols="12"
        name="other_mobile"
        persistent-hint
        rules="mobile"
        sm="6"
      />
      <app-text-input
        v-model="form.email"
        clearable
        cols="12"
        name="email"
        sm="6"
      />
      <app-text-input
        v-model="form.id_number"
        clearable
        cols="12"
        counter
        name="id_number"
        rules="digits:10"
        sm="6"
      />
      <app-text-input
        v-model="form.nationality"
        clearable
        cols="12"
        name="nationality"
        sm="6"
      />
    </v-row>
    <v-row>
      <app-products
        v-model="form.products_id"
        clearable
        cols="12"
        multiple
        name="products_id"
        required
        sm="6"
      />
      <app-price-input
        v-model="form.reservation_amount"
        :suffix="$t('sar')"
        cols="12"
        name="reservation_amount"
        required
        sm="6"
      />
      <app-price-input
        v-model="form.insurance_amount"
        :suffix="$t('sar')"
        cols="12"
        name="insurance_amount"
        required
        sm="6"
      />
      <app-number-input
        v-model="form.discount"
        clearable
        cols="12"
        name="discount"
        sm="6"
        suffix="%"
      >
        <template>
          <app-btn
            v-if="!form.customer_id"
            :loading="loadingCustomerDiscount"
            class="mt-1"
            small
            @click="fetchCustomerDiscount()"
          >
            {{ $t('messages.check_from_discount') }}
          </app-btn>
        </template>
      </app-number-input>
      <app-reservation-statuses
        v-if="!hideStatus"
        v-model="form.status"
        cols="12"
        name="status"
        required
        sm="6"
      />
    </v-row>
    <v-row>
      <app-textarea-input
        v-model="form.services"
        clearable
        cols="12"
        name="services"
      />
      <app-price-input
        v-model="form.services_amount"
        :suffix="$t('sar')"
        clearable
        cols="12"
        name="services_amount"
        sm="6"
      />
    </v-row>
    <v-row>
      <app-textarea-input
        v-model="form.damages"
        clearable
        cols="12"
        name="damages"
      />
      <app-price-input
        v-model="form.damages_amount"
        :suffix="$t('sar')"
        clearable
        cols="12"
        name="damages_amount"
        sm="6"
      />
    </v-row>
    <!--<v-row>-->
    <!--  <app-textarea-input-->
    <!--    v-model="form.notes"-->
    <!--    clearable-->
    <!--    cols="12"-->
    <!--    name="notes"-->
    <!--  />-->
    <!--</v-row>-->
    <v-row>
      <v-col cols="12">
        <h3><span>{{ parseAttribute('total') }}:</span>{{ $helpers.toNumberFormat(netTotal) }}</h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import SearchOnCustomer from '@components/base/search/SearchOnCustomer'

export default {
  name: 'ReservationForm',
  components: { SearchOnCustomer },
  props: {
    hideStatus: {
      type: Boolean,
      default: () => !1
    },
    form: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loadingCustomerDiscount: !1
    }
  },
  computed: {
    netTotal () {
      const v = this.form
      let reservationAmount = parseFloat(v.reservation_amount) || 0
      const discount = parseFloat(v.discount) || 0
      const insuranceAmount = parseFloat(v.insurance_amount) || 0
      const servicesAmount = parseFloat(v.services_amount) || 0
      const damagesAmount = parseFloat(v.damages_amount) || 0

      if (discount) {
        reservationAmount *= (1 - (discount / 100))
      }

      return (reservationAmount + servicesAmount + damagesAmount) + insuranceAmount
    }
  },
  methods: {
    customerInput (v) {
      if (v) {
        this.$nextTick(() => {
          if (v?.name) {
            this.$nextTick(() => (this.updateForm({
              name: v?.name,
              mobile: v?.mobile,
              email: v?.email,
              id_number: v?.id_number,
              nationality: v?.nationality,
              other_mobile: v?.other_mobile,
              discount: 0
            })))
          }

          this.$nextTick(() => (this.fetchCustomerDiscount(v)))
        })
      }
    },
    removeCustomer () {
      this.$nextTick(() => (this.updateForm({
        name: undefined,
        mobile: undefined,
        email: undefined,
        id_number: undefined,
        nationality: undefined,
        other_mobile: undefined,
        discount: 0
      })))
    },
    fetchCustomerDiscount (customer) {
      // if (!customer) return
      const controller = new AbortController()
      this.loadingCustomerDiscount && controller.abort()
      this.$nextTick(() => {
        // console.log('Fetch Discount')
        this.loadingCustomerDiscount = !0
        this.apiService.discount.getDiscount(customer?.id, { signal: controller.signal }).then(({ _data, _message }) => {
          if (_data?.found === !0) {
            // console.log(_data.percentage)
            this.$nextTick(() => (this.updateForm({ discount: _data.percentage, notes: _message })))
            _message && this.alertMessage(_message)
          }
        }).catch((e) => e).finally(() => {
          this.loadingCustomerDiscount = !1
        })
      })
    },
    updateForm (v) {
      this.$emit('update:form', { ...this.form, ...v })
    }
  }
}
</script>
