/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import axios from 'axios'
import { StubUrl } from '@app/Stub'

const url = StubUrl('Static')
const Reservation = StubUrl(url('Reservation'))
const Payment = StubUrl(url('Payment'))

export default {
  search: (query) => axios.post(url('Search'), { query }),
  contactUs: (form, config = {}) => axios.post(url('Contact-Us'), form, config),
  setting: () => axios.get(url('PAS')),
  reservationStatuses: () => axios.get(Reservation('Statuses')),
  paymentTypes: () => axios.get(Payment('PaymentTypes'))
}
