/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  id: 'ID',
  login_id: 'حقل الدخول',
  user_id: 'المستخدم',
  roles_id: 'الوظائف',
  permission_id: 'الصلاحية',
  permissions_id: 'الصلاحيات',
  role_id: 'مجموعة المستخدم',
  payment_method_id: 'طريقة الدفع',
  payment_id: 'الدفعة',
  invoice_id: 'رقم الفاتورة',
  contract_term_id: 'بند العقد',
  classification_id: 'التصنيف',
  classifications_id: 'التصنيفات',
  reservation_id: 'الحجز',
  customer_id: 'العميل',
  product_id: 'المنتجع',
  products_id: 'المنتجعات',
  payment_category_id: 'فئة الدفعة',
  payment_categories_id: 'فئات الدفعات',
  username: 'اسم المستخدم',
  name: 'الاسم',
  name_ar: 'الاسم بالعربية',
  name_en: 'الاسم بالانجليزية',
  password: 'كلمة المرور',
  password_confirmation: 'تأكيد كلمة المرور',
  email: 'البريد الإلكتروني',
  mobile: 'رقم الجوال',
  phone: 'رقم الهاتف',
  whatsapp: 'Whatsapp',
  code: 'الرمز',
  two_factor_auth: 'التحقق بخطوتين',
  date: 'التاريخ',
  from_date: 'التاريخ من',
  to_date: 'التاريخ إلى',
  description: 'الوصف',
  description_ar: 'الوصف بالعربية',
  description_en: 'الوصف بالانجليزية',
  active: 'فعال',
  token: 'الرمز',
  type: 'النوع',
  status: 'الحالة',
  push_token: 'رمز الجهاز',
  verification_code: 'رمز التحقق',
  attachment: 'المرفق',
  attachments: 'المرفقات',
  send_time: 'وقت الإرسال',
  locale: 'اللغة',
  app_name: 'اسم الموقع',
  app_name_ar: 'اسم البرنامج بالعربية',
  app_name_en: 'اسم البرنامج بالإنجليزية',
  notification_email: 'بريد الإشعارات',
  notification_mobile: 'جوال الإشعارات',
  web: 'رابط الموقع',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'بريد خدمة العملاء',
  customer_service_mobile: 'جوال خدمة العملاء',
  customer_service_phone: 'هاتف خدمة العملاء',
  google_map_url: 'رابط قوقل ماب',
  app_store_url: 'رابط التطبيق App Store',
  play_store_url: 'رابط التطبيق Play Store',
  address: 'العنوان',
  address_ar: 'العنوان بالعربية',
  address_en: 'العنوان بالإنجليزية',
  contact_us: 'تواصل معنا',
  contact_us_ar: 'تواصل معنا بالعربية',
  contact_us_en: 'تواصل معنا بالانجليزية',
  about_us: 'من نحن',
  about_us_ar: 'من نحن بالعربية',
  about_us_en: 'من نحن بالانجليزية',
  privacy_policy: 'سياسة الخصوصية',
  privacy_policy_ar: 'سياسة الخصوصية بالعربية',
  privacy_policy_en: 'سياسة الخصوصية بالانجليزية',
  terms_and_conditions: 'الشروط والأحكام',
  terms_and_conditions_ar: 'الشروط والأحكام بالعربية',
  terms_and_conditions_en: 'الشروط والأحكام بالانجليزية',
  commercial_register: 'السجل التجاري',
  commercial_name: 'الاسم التجاري',
  commercial_name_ar: 'الاسم التجاري بالعربية',
  commercial_name_en: 'الاسم التجاري بالإنجليزية',
  vat_number: 'الرقم الضريبي',
  notification_methods: 'طرق الإشعارات',
  avatar: 'الصورة',
  order_by: 'ترتيب العرض',
  key: 'المفتاح',
  created_at: 'تاريخ الإضافة',
  from_created_at: 'تاريخ الإضافة من',
  to_created_at: 'تاريخ الإضافة إلى',
  message: 'الرسالة',
  price: 'السعر',
  notes: 'ملاحظات',
  icon: 'الأيقونة',
  subject: 'العنوان',
  image: 'الصورة',
  images: 'الصور',
  choose_image: 'اختر صورة',
  update_image: 'تعديل الصورة',
  blobAvatar: 'الصورة',
  url: 'الرابط',
  amount: 'القيمة',
  discount: 'الخصم',
  discount_type: 'نوع الخصم',
  discount_amount: 'قيمة الخصم',
  meta: 'بيانات',
  quantity: 'الكمية',
  location: 'الموقع',
  tax: 'الضريبة',
  payable_id: 'رقم المرجع',
  payable_type: 'نوع المرجع',
  total: 'المجموع',
  data: 'البيانات',
  from_discount_amount: 'قيمة الخصم من',
  to_discount_amount: 'قيمة الخصم إلى',
  expire_date: 'تاريخ الانتهاء',
  from_expire_date: 'تاريخ الانتهاء من',
  to_expire_date: 'تاريخ الانتهاء الى',
  read: 'تم القراءة',
  ref_key: 'الرقم المرجعي',
  attachment_type: 'نوع المرفق',
  percentage: 'النسبة',
  vat: 'الضريبة',
  vat_amount: 'قيمة الضريبة',
  payment_date: 'تاريخ الدفعة',
  from_payment_date: 'تاريخ الدفعة من',
  to_payment_date: 'تاريخ الدفعة إلى',
  payment_type: 'نوع الدفعة',
  paid_amount: 'المبلغ المدفوع',
  color: 'اللون',
  symbol: 'الرمز',
  contact: 'التواصل',
  start_date: 'تاريخ البداية',
  from_start_date: 'تاريخ البداية من',
  to_start_date: 'تاريخ البداية إلى',
  end_date: 'تاريخ النهاية',
  from_end_date: 'تاريخ النهاية من',
  to_end_date: 'تاريخ النهاية إلى',
  start_time: 'وقت البداية',
  from_start_time: 'وقت البداية من',
  to_start_time: 'وقت البداية إلى',
  end_time: 'وقت النهاية',
  from_end_time: 'وقت النهاية من',
  to_end_time: 'وقت النهاية إلى',
  database: 'البرنامج',
  mail: 'البريد الإلكتروني',
  sms: 'الرسائل النصية',
  pay_date: 'تاريخ الدفع',
  from_pay_date: 'تاريخ الدفع من',
  to_pay_date: 'تاريخ الدفع إلى',
  due_amount: 'المبلغ المستحق',
  customer_name: 'اسم العميل',
  signature: 'التوقيع',
  stamp: 'الختم',
  reservations_count: 'عدد الحجوزات',
  reservations_from: 'الحجوزات من',
  reservations_to: 'الحجوزات إلى',
  classifications: 'التصنيفات',
  reservation_update_limit: 'عدد مرات تعديل الحجز',
  reservation_amount: 'قيمة الحجز',
  insurance_amount: 'قيمة التأمين',
  services: 'الخدمات',
  services_amount: 'قيمة الخدمات',
  damages: 'التآلف',
  damages_amount: 'قيمة التآلف',
  contract_number: 'رقم العقد',
  reservation_key: 'معرف الحجز',
  updates_count: 'عدد التعديلات',
  id_number: 'رقم الهوية',
  customer_code: 'رقم العميل',
  down_payment: 'الدفعة المقدمة',
  rest_amount: 'المبلغ المتبقي',
  new_reservation_period: 'مدة الحجز المبدئي بالدقائق',
  payments_count: 'عدد الدفعات',
  name_and_mobile: 'الاسم والجوال',
  refund_insurance: 'إرجاع قيمة التأمين',
  insurance_payment_method: 'طريقة إرجاع قيمة التأمين',
  permit_no: 'رقم التصريح',
  tenant_name: 'اسم المستأجر',
  contract_line_ar: 'المواعيد بالعربية',
  contract_line_en: 'المواعيد بالإنجليزية',
  nationality: 'الجنسية',
  reservation_start_hour: 'ساعة بداية الحجز',
  reservation_hours: 'ساعات الحجز في اليوم',
  signing_date: 'تاريخ التوقيع',
  other_mobile: 'جوال آخر',
  net_total: 'الصافي',
  refund_amount: 'المبلغ المردود',
  insurance_amount_of_reservation: 'قيمة تأمين الحجز',
  profit_loss_report: 'الأرباح والخسائر',
  insurance_payment: 'دفعة تأمين',
  payment_group_id: 'مجموعة الدفعات',
  payment_groups_id: 'مجموعات الدفعات',
  deposit: 'استلام',
  withdraw: 'صرف'
}
