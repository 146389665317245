/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  loginSubtitle: 'Welcome back',
  forgetPassword: 'Forget password ?',
  no_account: 'No account? ',
  create_account: 'Create new account'
}
