/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  Auths: 'Auth|Auths',
  SideMenus: 'Side Menu|Side Menus',
  Ages: '{0}-|[1]One Year|[2,*]:age Years',
  Days: '{0}-|[1]One Day|[2,*]:days Days',
  Minutes: '{0}-|[1]One Minute|[2,*]:minutes Minutes',
  Hours: '{0}-|[1]One Hour|[2,*]:hours Hours',
  Months: '{0}-|[1]One Month|[2,*] :months Month',
  MediaFiles: 'Attachment|Attachments',
  Attachments: 'Attachment|Attachments',
  Permissions: 'Permission|Permissions',
  Roles: 'Role|Roles',
  Users: 'User|Users',
  Utilities: 'Utility|Utilities',
  Notifications: 'Notification|Notifications',
  Settings: 'Setting|Settings',
  PaymentMethods: 'Payment Method|Payment Methods',
  ContractTerms: 'Contract Term|Contract Terms',
  Customers: 'Customer|Customers',
  Classifications: 'Classification|Classifications',
  Discounts: 'Discount|Discounts',
  Products: 'Chalet|Chalets',
  Payments: 'Payment|Payments',
  Reservations: 'Reservation|Reservations',
  Reports: 'Report|Reports',
  ReservationsReports: 'Reservations Report|Reservations Reports',
  PaymentsReports: 'Payments Report|Payments Reports',
  PaymentCategories: 'Payment Category|Payment Categories',
  PaymentGroups: 'Payment Group|Payment Groups',
  Incomes: 'Income|Incomes',
  Expenses: 'Expense|Expenses'
}
