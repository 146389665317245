<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-row>
      <reservation-form
        :form.sync="computedForm"
        hide-status
      />
    </v-row>
  </v-container>
</template>

<script>
import ReservationForm from '@components/reservation/ReservationForm'

export default {
  name: 'ReservationCalendarForm',
  components: { ReservationForm },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    date: {
      type: String,
      default: () => ''
    },
    product: {
      type: [Array, String, Number],
      default: () => undefined
    }
  },
  data () {
    return {
      loading: !1
    }
  },
  computed: {
    computedForm: {
      get () {
        return this.form
      },
      set (v) {
        this.setForm(v)
      }
    }
  },
  watch: {
    date: {
      deep: !0,
      handler (v) {
        this.setForm({ from_date: v, to_date: v })
      }
    },
    product: {
      deep: !0,
      handler (v) {
        this.setForm({ products_id: v })
      }
    }
  },
  mounted () {
    // console.log(this.product)
    this.setForm({ from_date: this.date, to_date: this.date, products_id: this.product })
  },
  methods: {
    setForm (v) {
      // this.form = { ...this.form, ...v }
      // console.log({ ...this.form, ...v })
      this.$emit('update:form', { ...this.form, ...v })
    }
  }
}
</script>
