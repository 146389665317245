<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-sheet
    v-if="authUser"
    class="fill-height"
    width="100%"
  >
    <!--<template v-if="dashboardPermission">-->
    <!--  <public-statistics-->
    <!--    ref="publicReport"-->
    <!--    :print="print"-->
    <!--  />-->
    <!--</template>-->
    <!--<v-row-->
    <!--  align="center"-->
    <!--  justify="center"-->
    <!--&gt;-->
    <!--  <v-col cols="auto">-->
    <!--    <p class="text-center text-h5">-->
    <!--      {{ $t('replace.hello', { n: authUser.name }) }}-->
    <!--    </p>-->
    <!--    <p class="text-center text-h6">-->
    <!--      {{ authUser.branch_id_to_string }}-->
    <!--    </p>-->
    <!--    &lt;!&ndash;<app-btn :to="route(APP_ROUTES..index)">&ndash;&gt;-->
    <!--    &lt;!&ndash;  {{ $t('replace.go_to', { v: $tc('choice.',2) }) }}&ndash;&gt;-->
    <!--    &lt;!&ndash;</app-btn>&ndash;&gt;-->
    <!--  </v-col>-->
    <!--  &lt;!&ndash;<v-btn @click="test">test</v-btn>&ndash;&gt;-->
    <!--</v-row>-->
    <!--<v-row no-gutters>-->
    <!--  <v-col cols="12">-->
    <!--  </v-col>-->
    <!--</v-row>-->
  </v-sheet>
</template>

<script>
import PublicStatistics from '@components/home-user/PublicStatistics'
import ReservationCalendar from '@components/reservation/ReservationCalendar'

export default {
  name: 'Home',
  components: { ReservationCalendar, PublicStatistics },
  data: () => ({}),
  computed: {
    dashboardPermission () {
      return this.userHasPermission('User.dashboard')
    }
  },
  mounted () {
    if (!this.authUser && window && window.location.pathname !== '/') {
      window.location.href = '/'
    }
    // console.log(this.value)
    // if (this.authUser) {
    // }

    // if (!this.dashboardPermission) {
    //   const name = this.APP_ROUTES.homePage
    //   if (!this.isRoute(name)) {
    //     this.$router.replace({ name })
    //   }
    // }
  },
  methods: {
    print () {
      const publicReport = this.$refs.publicReport?.$el
      if (!publicReport) return

      publicReport.classList.add('d-print-none')
      window.print()
      publicReport.classList.remove('d-print-none')
    }
  }
}
</script>
