<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :form.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      @submitClickForm="submitClickForm"
    >
      <template #form="props">
        <app-form
          ref="form"
          v-slot="v"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-container>
            <v-row>
              <app-text-input
                v-model="selectedItem.name"
                cols="12"
                name="name"
                required
                sm="6"
              />
              <app-number-input
                v-model="selectedItem.order_by"
                cols="12"
                name="order_by"
                sm="6"
              />
            </v-row>
            <v-row justify="space-between">
              <v-col cols="auto">
                <app-btn
                  :disabled="formLoading"
                  color="accent"
                  @click="showPermissionDialog"
                >
                  {{ $t('show_permissions') }}
                  <span
                    v-if="isNewItem"
                    class="error--text ps-1"
                  >*
                  </span>
                </app-btn>
              </v-col>
            </v-row>
          </v-container>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy') && item.id > 1"
          :disabled="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
      </template>

      <dialog-modal
        v-model="permissionDialog"
        fullscreen
        max-width="960"
        no-click-animation
        persistent
      >
        <v-card tile>
          <v-toolbar
            class="mb-4"
            color="primary"
          >
            <v-toolbar-title>{{ $t('replace.role_permissions', { n: selectedItem.name }) }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <app-row>
                <app-auto-select
                  v-model="selectedItem.permissions_id"
                  :clearable="false"
                  :items="permissions"
                  append-icon="search"
                  hide-details
                  hide-selected
                  label="search"
                  multiple
                  name="search"
                >
                  <template #selection />
                </app-auto-select>
                <v-col cols="12">
                  <v-responsive
                    class="overflow-y-auto"
                    max-height="500"
                  >
                    <v-container>
                      <v-row>
                        <app-checkbox-group
                          v-model="selectedItem.permissions_id"
                          :items="permissions"
                        />
                      </v-row>
                    </v-container>
                  </v-responsive>
                </v-col>
              </app-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <app-btn
              color="success"
              @click="togglePermissionDialog"
            >
              {{ $t('done') }}
            </app-btn>
            <v-spacer />
            <app-btn
              color="error"
              @click="closePermissionDialog"
            >
              {{ $t('close') }}
            </app-btn>
          </v-card-actions>
        </v-card>
      </dialog-modal>
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, MetaInfoMixin } from '@mixins'
import DialogModal from '@components/base/grid/DialogModal'

export default {
  name: 'Roles',
  components: { DialogModal },
  helperApiName: 'role',
  mixins: [MetaInfoMixin, DatatableMixin],
  data () {
    return {
      modelPermission: 'Role',
      headers: ['name', 'control'],
      permissions: [],
      tempPermissions: [],
      permissionDialog: !1
    }
  },
  computed: {
    defaultSelectedItem () {
      return {
        permissions_id: [],
        order_by: 0
      }
    }
  },
  mounted () {
    this.checkPermission('index')
    this.$nextTick(this.fetchPermissions)
  },
  methods: {
    togglePermissionDialog () {
      this.permissionDialog = !this.permissionDialog
    },
    showPermissionDialog () {
      this.permissionDialog = !0
      this.tempPermissions = this.selectedItem.permissions_id
    },
    closePermissionDialog () {
      this.permissionDialog = !1
      this.selectedItem.permissions_id = this.tempPermissions
    },
    fetchPermissions () {
      this.apiService.permission.utilities().then(({ _data }) => (this.permissions = _data || [])).catch(e => e)
    }
  }
}
</script>
