<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-col-span v-bind="$attrs">
    <ValidationProvider
      ref="provider"
      v-slot="v"
      :mode="mode"
      :name="name"
      :rules="getRules"
      :vid="vid"
    >
      <v-container
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <app-top-input-label
            v-if="getLabel && !hideTopLabel"
            :label="getTopLabel"
          />
          <v-col>
            <slot name="top" />
            <v-file-input
              :accept="accept"
              :clearable="getClearable"
              :counter="counter"
              :counter-value="a => a ? a.length : 0"
              :disabled="disabled"
              :error="getErrorMessages.length>0"
              :error-count="getErrorCount"
              :error-messages="[...getErrorMessages,...v.errors]"
              :hide-details="hideDetails"
              :label="getLabel"
              :placeholder="getPlaceholder"
              :readonly="readonly"
              :show-size="showSize"
              :value="value"
              color="primary"
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="attachment"
              style="overflow: hidden"
              v-bind="$attrs"
              v-on="{...$listeners,change:input}"
            />
            <slot />
            <slot name="bottom" />
          </v-col>
        </v-row>
      </v-container>
    </ValidationProvider>
  </app-col-span>
</template>

<script>
import { InputsMixin } from '@mixins'

export default {
  name: 'FileInput',
  mixins: [InputsMixin],
  props: {
    showSize: {
      type: Boolean,
      default: () => true
    },
    accept: {
      type: String,
      default: () => undefined
    }
  },
  methods: {
    input (v) {
      this.$emit('input', v)
    },
    change (v) {
      this.$emit('change', v)
    }
  }
}
</script>
