<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-form
    ref="dialogForm"
    v-slot="{invalid}"
    :errors.sync="formErrors"
  >
    <dialog-modal
      v-model="modal"
      max-width="550"
      no-click-animation
      persistent
      scrollable
    >
      <slide-fade-transition>
        <v-card v-if="dialogItem">
          <v-card-title>
            {{ $t('reservationPage.confirm') }}
            <v-spacer />
            <v-btn
              :disabled="loadingData"
              icon
              @click="closeDialog"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <app-price-input
                  v-model="itemForm.down_payment"
                  :disabled="loadingData"
                  :rules="`max_value:${dialogItem.total}`"
                  :suffix="$t('sar')"
                  clearable
                  cols="12"
                  name="down_payment"
                  required
                />
                <app-payment-methods
                  v-model="itemForm.payment_method_id"
                  :clearable="parseFloat(itemForm.down_payment)===0"
                  :disabled="loadingData || !parseFloat(itemForm.down_payment)"
                  :required="parseFloat(itemForm.down_payment)>0"
                  cols="12"
                  name="payment_method_id"
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <app-btn
              :disabled="invalid"
              :loading="loadingData"
              color="success"
              @click="submit"
            >
              {{ $t('reservationPage.confirm') }}
            </app-btn>
          </v-card-actions>
        </v-card>
      </slide-fade-transition>
    </dialog-modal>
  </app-form>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'ConfirmReservationDialog',
  components: { SlideFadeTransition, DialogModal },
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modal: !1,
      dialogItem: undefined,
      dialogIndex: undefined,
      loadingData: !1,
      formErrors: {},
      itemForm: {
        down_payment: null,
        payment_method_id: null
      }
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', { item: this.dialogItem, index: this.dialogIndex })
      }
    },
    loadingData: {
      deep: !0,
      handler (v) {
        this.$emit('update:loading', v)
      }
    },
    formErrors: {
      deep: !0,
      handler (v) {
        this.$emit('update:errors', v)
      }
    },
    itemForm: {
      deep: !0,
      handler (v) {
        this.$emit('update:form', v)
      }
    }
  },
  methods: {
    openDialog (item, index) {
      this.dialogItem = item
      this.dialogIndex = index
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
        this.dialogIndex = undefined
        this.itemForm = {}
        this.formErrors = {}
        this.$refs.dialogForm?.reset()
      })
    },
    async submit () {
      if (this.loadingData) return
      this.loadingData = !0
      this.formErrors = {}
      try {
        const form = { ...this.itemForm }
        if (!form.down_payment) {
          form.payment_method_id = undefined
        }
        form.down_payment = Math.abs(parseFloat(form.down_payment)) || 0
        const { _data, _message, _success } = await this.apiService.reservation.confirm(this.dialogItem.id, form)
        if (_success === !0) {
          _message && this.alertSuccess(_message)
          _data && this.$emit('confirmed', { item: _data, index: this.dialogIndex })
          this.$nextTick(() => (this.closeDialog()))
        }
      } catch ({ _message, _errors }) {
        _message && this.alertError(_message)
        this.formErrors = _errors || {}
      } finally {
        this.loadingData = !1
      }
    }
  }
}
</script>
