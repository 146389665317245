<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <dialog-modal
    v-model="dialog"
    max-width="980"
    no-click-animation
    persistent
    scrollable
  >
    <slide-fade-transition>
      <v-card
        v-if="dialogItem"
        ref="divRef"
      >
        <v-card-title>
          {{ $t('reservationPage.details') }}
          <v-spacer />
          <v-btn
            icon
            @click="closeShowDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          ref="showReservationCard"
        >
          <slide-fade-transition>
            <v-container
              v-if="dialogItem"
              fluid
            >
              <v-row>
                <v-col cols="auto">
                  <reservation-sheet
                    :color="dialogItem.event_color"
                    :text="parseAttribute('status')"
                    :value="dialogItem.status_to_string"
                  />
                </v-col>
                <v-col cols="auto">
                  <reservation-sheet
                    :color="dialogItem.event_color"
                    :text="parseAttribute('date')"
                    :value="dialogItem.date_to_string"
                  />
                </v-col>
                <v-spacer />
                <template v-if="dialogItem.is_new === !0">
                  <v-col cols="auto">
                    <app-btn
                      v-if="userHasPermission('updateDate')"
                      :disabled="loading"
                      color="success"
                      @click="onUpdateReservationDate"
                    >
                      <v-icon left>
                        edit
                      </v-icon>
                      {{ $t('reservationPage.update_date') }}
                    </app-btn>
                  </v-col>
                </template>
              </v-row>
              <v-row dense>
                <template
                  v-for="(e,i) in itemHeaders"
                >
                  <v-col
                    v-if="e.divider"
                    :key="i.toString()"
                    cols="12"
                  >
                    <v-divider />
                  </v-col>
                  <v-col
                    v-else
                    :key="i.toString()"
                    align-self="center"
                    cols="12"
                    md="4"
                    sm="6"
                  >
                    <reservation-sheet
                      :text="e.text"
                      :value="dialogItem[e.value]"
                    />
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </slide-fade-transition>
          <confirm-reservation-dialog
            ref="confirmDialog"
            @confirmed="refreshItemData"
          />
          <complete-reservation-dialog
            ref="completeDialog"
            @completed="refreshItemData"
          />
          <reservation-payments-dialog
            ref="paymentsDialog"
          />
          <reservation-payment-dialog
            ref="paymentDialog"
            @created="refreshItemData"
          />
          <update-date-reservation-dialog
            ref="updateDateReservationDialog"
            @saved="refreshItemData"
          />
        </v-card-text>
        <v-card-actions>
          <template v-if="dialogItem.can_confirm === !0">
            <app-btn
              v-if="userHasPermission('confirm')"
              :disabled="loading"
              color="error"
              @click="openConfirmDialog"
            >
              {{ $t('reservationPage.confirm') }}
            </app-btn>
          </template>
          <template v-if="dialogItem.can_complete === !0">
            <app-btn
              v-if="userHasPermission('complete')"
              :disabled="loading"
              color="error"
              @click="openCompleteDialog"
            >
              {{ $t('reservationPage.complete') }}
            </app-btn>
          </template>
          <v-spacer />
          <app-btn
            v-if="userHasPermission('Payment.index')"
            :disabled="loading"
            color="success"
            @click="openPaymentsDialog"
          >
            {{ $t('reservationPage.payments') }}
          </app-btn>
          <template v-if="dialogItem.is_new === !1">
            <app-btn
              v-if="userHasPermission('Payment.store')"
              :disabled="loading"
              @click="openPaymentDialog"
            >
              {{ $t('reservationPage.add_payment_reservation') }}
            </app-btn>
          </template>
          <app-btn
            v-if="userHasPermission('contract')"
            :loading="loading"
            @click="onContractClick"
          >
            {{ $t('reservationPage.contract') }}
          </app-btn>
          <template v-if="!dialogItem.signature">
            <app-btn
              v-if="userHasPermission('contractSigningUrl')"
              :loading="loading"
              color="success"
              @click="onContractSigning"
            >
              {{ $t('reservationPage.contract_signing') }}
            </app-btn>
            <app-btn
              v-if="userHasPermission('contractSigningUrl')"
              :loading="loading"
              color="warning"
              @click="onContractSigning({h:1})"
            >
              {{ $t('reservationPage.contract_signing_for_apps') }}
            </app-btn>
          </template>
        </v-card-actions>
      </v-card>
    </slide-fade-transition>
  </dialog-modal>
</template>

<script>

import { GetHeadersMixin } from '@mixins'
import ReservationSheet from '@components/reservation/ReservationSheet'
import ConfirmReservationDialog from '@components/reservation/ConfirmReservationDialog'
import DialogModal from '@components/base/grid/DialogModal'
import SlideFadeTransition from '@components/SlideFadeTransition'
import CompleteReservationDialog from '@components/reservation/CompleteReservationDialog'
import ReservationPaymentDialog from '@components/reservation/ReservationPaymentDialog'
import ReservationPaymentsDialog from '@components/reservation/ReservationPaymentsDialog'
import UpdateDateReservationDialog from '@components/reservation/UpdateDateReservationDialog'

export default {
  name: 'ShowReservation',
  components: { UpdateDateReservationDialog, ReservationPaymentsDialog, ReservationPaymentDialog, CompleteReservationDialog, SlideFadeTransition, DialogModal, ConfirmReservationDialog, ReservationSheet },
  mixins: [GetHeadersMixin],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      modelPermission: 'Reservation',
      dialog: !1,
      loading: !1,
      dialogItem: undefined,
      dialogIndex: undefined
    }
  },
  computed: {
    itemHeaders () {
      return this.parseHeaders([
        'reservation_key',
        'customer_id_to_string',
        'name',
        'mobile',
        'other_mobile',
        'email',
        'id_number',
        'nationality',
        'products_id_to_string',
        'updates_count',
        'payments_count',
        {
          divider: !0
        },
        'services_amount_to_string',
        'damages_amount_to_string',
        'reservation_amount_to_string',
        'discount_to_string',
        'net_total_to_string',
        'insurance_amount_to_string',
        'total_to_string',
        'paid_amount_to_string',
        'refund_amount_to_string',
        'rest_amount_to_string'
      ])
    }
  },
  watch: {
    dialog: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', {})
      }
    }
  },
  methods: {
    openShowDialog (item, index) {
      this.dialogItem = item
      this.dialogIndex = index
      this.$nextTick(() => (this.dialog = !0))
    },
    closeShowDialog () {
      this.dialog = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
        this.dialogIndex = undefined
      })
    },
    openConfirmDialog () {
      this.$refs.confirmDialog.openDialog(this.dialogItem, this.dialogIndex)
    },
    openCompleteDialog () {
      this.$refs.completeDialog.openDialog(this.dialogItem, this.dialogIndex)
    },
    openPaymentsDialog () {
      this.$refs.paymentsDialog.openDialog(this.dialogItem, this.dialogIndex)
    },
    openPaymentDialog () {
      this.$refs.paymentDialog.openDialog(this.dialogItem, this.dialogIndex)
    },
    async onContractClick () {
      if (this.loading || !this.dialogItem?.id) return
      this.loading = !0
      try {
        const { _data } = await this.apiService.reservation.contractUrl(this.dialogItem.id)
        if (_data?.url) {
          this.openWindow(_data.url)
        }
      } catch (e) {
        const { _message } = e
        this.alertError(_message || e?.message)
      } finally {
        this.loading = !1
      }
    },
    refreshItemData ({ item, index }) {
      this.dialogItem = item
      this.dialogIndex = index
      this.$emit('refresh', { item, index })
    },
    onUpdateReservationDate () {
      this.$refs.updateDateReservationDialog.openDialog(this.dialogItem, this.dialogIndex)
    },
    async onContractSigning (params) {
      if (this.loading || !this.dialogItem?.id) return
      this.loading = !0
      try {
        const { _data } = await this.apiService.reservation.contractSigningUrl(this.dialogItem.id, params)
        if (_data?.url) {
          this.copyText(_data.url, this.$refs.divRef.$el)
          // console.log(_data.url)
          this.alertSuccess(this.$t('reservationPage.link_copied'), null, {
            showDenyButton: true,
            denyButtonText: this.$t('reservationPage.open_whatsapp')
          }).then(r => {
            if (r?.isDenied === !0) {
              this.openWindow(`https://wa.me?text=${encodeURI(_data.url)}`)
            }
          })
          // this.openWindow(_data.url)
        }
      } catch (e) {
        const { _message } = e
        this.alertError(_message || e?.message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
