<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row>
    <slot name="before" />
    <app-text-input
      v-model="form.username"
      :disabled="profile"
      :hint="$t('hints.username')"
      :readonly="profile"
      cols="12"
      name="username"
      required
      sm="6"
    />
    <app-text-input
      v-model="form.name_ar"
      :disabled="profile"
      :readonly="profile"
      cols="12"
      name="name_ar"
      required
      sm="6"
    />
    <app-text-input
      v-model="form.name_en"
      :disabled="profile"
      :readonly="profile"
      cols="12"
      name="name_en"
      required
      sm="6"
    />
    <app-mobile-input
      v-model="form.mobile"
      :disabled="profile"
      :hint="$t('hints.mobile')"
      :readonly="profile"
      clearable
      cols="12"
      name="mobile"
      required
      sm="6"
    />
    <app-text-input
      v-model="form.email"
      :disabled="profile"
      :readonly="profile"
      clearable
      cols="12"
      name="email"
      rules="email"
      sm="6"
    />
    <slot />
    <slot name="after" />
  </v-row>
</template>
<script>

export default {
  name: 'AccountForm',
  props: {
    value: {
      type: Object,
      default: () => undefined
    },
    profile: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {}
  },
  computed: {
    form: {
      set (v) {
        this.$emit('input', v)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>
