<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <reservation-calendar
      v-if="userHasPermission('calendar')"
    />
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'
import ReservationCalendar from '@components/reservation/ReservationCalendar'

export default {
  name: 'Calendar',
  components: { ReservationCalendar },
  helperApiName: 'reservation',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Reservation'
    }
  },
  mounted () {
    // this.checkPermission('calendar')
  }
}
</script>
