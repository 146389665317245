/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  datatable_search: 'Typing to search...',
  username: 'Login username',
  name: 'Your full name',
  mobile: 'Your mobile number',
  tenant_mobile: 'Tenant mobile number',
  reservation_user_id: 'The user responsible for the reservation',
  payment_user_id: 'The user responsible for the payment',
  customer_no_data_text: 'There is no customer with this data, add a new customer',
  organization_name: 'Organization Name',
  commercial_register: 'Commercial Register number',
  vat_number: 'TAX number in your country',
  search_on_customer: 'Search for a customer by name or mobile number',
  payment_method_id_complete: 'Payment Method of rest amount'
}
