<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <dialog-modal
    v-model="modal"
    no-click-animation
    persistent
    scrollable
  >
    <slide-fade-transition>
      <v-card v-if="modal">
        <template v-if="userHasPermission('index')">
          <v-card-title>
            {{ $t('reservationPage.payments') }}
            <v-spacer />
            <v-btn
              icon
              @click="closeDialog"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <app-datatable
              ref="datatable"
              :headers="headers"
              :modal-title="datatableDialogTitle"
              :search="!1"
              :url="tableUrl"
            >
              <template #filter="{datatableFilters}">
                <v-container>
                  <v-row>
                    <app-payment-methods
                      v-model="datatableFilters.payment_method_id"
                      clearable
                      cols="12"
                      multiple
                      name="payment_method_id"
                      sm="6"
                    />
                    <app-payment-types
                      v-model="datatableFilters.payment_type"
                      clearable
                      cols="12"
                      multiple
                      name="payment_type"
                      sm="6"
                    />
                  </v-row>
                  <v-row>
                    <app-date-picker
                      v-model="datatableFilters.from_payment_date"
                      cols="12"
                      name="from_payment_date"
                      sm="6"
                    />
                    <app-date-picker
                      v-model="datatableFilters.to_payment_date"
                      cols="12"
                      name="to_payment_date"
                      sm="6"
                    />
                  </v-row>
                </v-container>
              </template>
              <template #item.control="{item}">
                <app-dt-btn
                  if="userHasPermission('show') && item.print_url"
                  @click="openWindow(item.print_url)"
                >
                  <v-icon>print</v-icon>
                </app-dt-btn>
              </template>
            </app-datatable>
          </v-card-text>
          <v-card-actions />
        </template>
      </v-card>
    </slide-fade-transition>
  </dialog-modal>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import { DatatableMixin, GetHeadersMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'ReservationPaymentsDialog',
  components: { SlideFadeTransition, DialogModal },
  mixins: [DatatableMixin, GetHeadersMixin],
  props: {
    value: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      modal: !1,
      dialogItem: undefined,
      modelPermission: 'Reservation.Payment',
      headers: [
        'payment_method_id_to_string',
        'payment_type_to_string',
        'amount_to_string',
        'description',
        'payment_date_to_string',
        'insurance_payment_to_string',
        'control'
        // {
        //   text: 'control',
        //   value: 'control',
        //   width: 20
        // }
      ]
    }
  },
  computed: {
    tableUrl () {
      if (!this.dialogItem?.id) return undefined
      return this.apiService.reservation.payments(!0, this.dialogItem?.id)
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', { item: this.dialogItem })
      }
    }
  },
  mounted () {
  },
  methods: {
    openDialog (item) {
      this.dialogItem = item
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
      })
    }
  }
}
</script>
