/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import axios from 'axios'

export default {
  login: post => axios.post('login', post),
  register: post => axios.post('register', post),
  forgotPassword: post => axios.post('forgot-password', post),
  verificationCode: post => axios.post('verification-code', post),
  resetPassword: post => axios.post('reset-password', post),
  logout: (params) => axios.get('logout', { params }),
  checkToken: () => axios.get('check-token')
}
