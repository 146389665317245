<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row>
    <slot name="before" />
    <app-password-input
      v-model="form.password"
      :rules="passRule"
      autocomplete="new-password"
      clearable
      cols="12"
      md="6"
      name="password"
    />
    <app-password-input
      v-model="form.password_confirmation"
      :rules="passConfirmRule"
      clearable
      cols="12"
      md="6"
      name="password_confirmation"
    />
    <slot />
  </v-row>
</template>
<script>

import { PasswordsMixin } from '@mixins'

export default {
  name: 'PasswordForm',
  mixins: [PasswordsMixin],
  props: {
    value: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    return {}
  },
  computed: {
    getItemForm () {
      return this.form
    },
    form: {
      set (v) {
        this.$emit('input', v)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>
