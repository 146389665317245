/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'

// const { mapActions } = createNamespacedHelpers('snackbar')

export default {
  methods: {
    alertMessage (text = '', type = null, callback = null, toast = !1, confirm = !1, args = {}) {
      if (_.isObject(text)) {
        type = text.type || null
        callback = text.callback || null
        text = text.text
        toast = Boolean(text.toast)
      }

      if (type && typeof type !== 'string') {
        callback = type
        type = null
      }
      const hasCallback = callback && typeof callback === 'function'
      const theme = this.$vuetify.theme.themes[this.themeMode]
      const cancelButtonText = this.$t('cancel')
      const confirmButtonText = this.$t(confirm ? 'yes' : 'done')
      const confirmButtonColor = confirm ? theme.error : theme.primary
      const denyButtonText = this.$t(confirm ? 'no' : 'cancel')
      const denyButtonColor = confirm ? theme.primary : theme.error

      const didClose = () => {
        if (hasCallback && !confirm) {
          callback()
        }
      }

      const swal = this.$swal.mixin({
        toast,
        timer: toast ? 3000 : undefined,
        timerProgressBar: toast,
        position: toast ? 'top-start' : 'center',
        backdrop: !toast,
        text,
        icon: type,
        allowOutsideClick: !1,
        allowEscapeKey: !1,
        confirmButtonText: confirmButtonText,
        confirmButtonColor,
        denyButtonText,
        denyButtonColor,
        cancelButtonText,
        showDenyButton: confirm,
        // showCancelButton: confirm,
        showConfirmButton: !toast,
        didClose,
        didOpen: toast
          ? (v) => {
              v.addEventListener('mouseenter', this.$swal.stopTimer)
              v.addEventListener('mouseleave', this.$swal.resumeTimer)
            }
          : undefined,
        target: '#main-app',
        ...args
      }).fire()
      if (hasCallback) {
        swal.then((v) => {
          if (v?.isConfirmed === !0) {
            callback(v)
          }
          return v
        })
      }
      return swal
      // if (_.isObject(text)) {
      //   type = text.type || null
      //   callback = text.callback || null
      //   text = text.text
      // }
      //
      // if (type && typeof type !== 'string') {
      //   callback = type
      //   type = undefined
      // }
      //
      // type = type || undefined
      // return mapActions(['showSnackbar']).showSnackbar.call(this, { text, type, callback })
    },
    alertError (text = '', callback = null, args = {}) {
      return this.alertMessage(text, 'error', callback, !1, !1, args)
    },
    alertSuccess (text = '', callback = null, args = {}) {
      return this.alertMessage(text, 'success', callback, !1, !1, args)
    },
    toast (text = '', type = 'info', callback = null) {
      return this.alertMessage(text, type, callback, !0)
      // return mapActions(['showSnackbar']).showSnackbar.call(this, { text, type, toast })
    },
    confirmMessage (text = '', callback = null, args = {}) {
      if (typeof text === 'function') {
        callback = text
        text = this.$t('messages.are_you_sure')
      }

      if (!text) text = this.$t('messages.are_you_sure')
      return this.alertMessage(text, 'question', callback, !1, !0, args)

      // if (typeof text === 'function') {
      //   callback = text
      //   text = this.$t('messages.are_you_sure')
      // }
      // return mapActions(['showSnackbar']).showSnackbar.call(this, { text, type, callback, confirm: !0, reject })
    }
  },
  computed: {
    AppSnackbar: {
      set (value) {
        mapMutations('snackbar', ['setShow']).setShow.call(this, value)
      },
      get () {
        return mapGetters('snackbar', ['getShow']).getShow.call(this)
      }
    }
  }
}
