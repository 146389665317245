/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  are_you_sure: 'هل أنت متأكد؟',
  sure_logout: 'هل أنت متأكد سوف يتم تسجيل الخروج من الحساب',
  exportAll: 'هل أنت متأكد من تصدير جميع البيانات؟',
  confirmDelete: 'هل أنت متأكد من حذف البيانات؟',
  error: 'حدث خطأ الرجاء المحاولة لاحقاً',
  whoops: 'عذراً',
  not_found_message: 'لم يتم العثور على البيانات المطلوبة',
  window_blocked: 'الرجاء التأكد من سماح فتح النوافذ المنبثقه في المتصفح لتستطيع تحميل الملفات. ينصح باستخدام متصفح قوقل كروم (Google Chrome) للحصول على جميع الخدمات والمميزات في البرنامج.',
  404: 'عذرا حدث خطأ..',
  403: 'لا يمكن الوصول الى البيانات المطلوبة',
  active_user_confirm: 'سوف يتم تفعيل الحساب, هل تريد المتابعة؟',
  inactive_user_confirm: 'سوف يتم تعطيل الحساب ولن يستطيع المستخدم استخدام الحساب, هل تريد المتابعة؟',
  max_size: 'يجب ان لا يتجاوز الحجم {size} MB',
  specifications_update: 'هل تريد استبدال مواصفات الصنف المخزنة في الحالية ؟',
  signature_is_empty: 'الرجاء إدراج التوقيع',
  check_from_discount: 'التحقق من الخصم',
  signature_saved: 'تم حفظ التوقيع بنجاح'
}
