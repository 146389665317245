<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-sheet
    :color="color"
    class="py-2 px-4 text-body-1"
    dark
    rounded
    v-bind="$attrs"
    width="auto"
    v-on="$listeners"
  >
    <span
      v-if="text !== undefined"
    >{{ text }}: </span>
    <span v-if="value !== undefined">{{ value }}</span>
  </v-sheet>
</template>

<script>

export default {
  name: 'ReservationSheet',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: [String, Number, Object, Array, Boolean],
      default: () => undefined
    },
    text: {
      type: [String, Number, null, undefined],
      default: () => undefined
    },
    color: {
      type: String,
      default: () => 'primary'
    }
  }
}
</script>
