/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { mapGetters, mapMutations } from 'vuex'

export default {
  methods: {
    route (name, params, args) {
      return { name, params, ...args }
    },
    isRoute (name) {
      return this.$route?.name === name
    },
    async fetchAppSettings (callback) {
      try {
        const { _data } = this.userHasPermission('Setting.show') ? await this.apiService.setting.all() : await this.apiService.utilities.setting()
        this.AppSettings = _data || {}
      } catch (e) {

      } finally {
        if (callback) {
          callback()
        }
      }
    },
    fetchAllAppSettings () {
      return this.apiService.setting.all()
    }
  },
  computed: {
    AppName () {
      return this.AppSettings.app_name || process.env.VUE_APP_NAME
    },
    AppDescription () {
      return this.AppSettings.app_description || process.env.VUE_APP_DESCRIPTION
    },

    AppLogo () {
      return require('@images/logo/logo.png')
      // return require(`@images/logo/${this.themeMode}.png`)
    },
    AppIcon () {
      return require('@images/logo/icon.png')
      // return require(`@images/logo/${this.themeMode}.png`)
    },
    AppLightLogo () {
      return require('@images/logo/light.png')
    },
    AppDarkLogo () {
      return require('@images/logo/dark.png')
    },

    AppSettings: {
      set (v) {
        this.$root.RootSettings = v
      },
      get () {
        return this.$root.RootSettings
      }
    },
    AppSocials () {
      const skip = ['address', 'description']
      const result = {}
      for (const k in this.AppSettings) {
        if (k.includes('_') || skip.indexOf(k) !== -1 || !this.AppSettings[k]) {
          continue
        }
        result[k] = this.AppSettings[k]
      }
      // console.log(result, this.AppSettings)
      return result
    },

    AppSideMenu: {
      set (value) {
        mapMutations('sideMenu', ['setItems']).setItems.call(this, value)
      },
      get () {
        return mapGetters('sideMenu', ['getItems']).getItems.call(this)
      }
    },

    AppNotifications: {
      set (v) {
        this.$root.RootNotifications = v
      },
      get () {
        return this.$root.RootNotifications
      }
    },
    UnreadAppNotifications: {
      set (v) {
        this.$root.RootUnreadNotifications = v
      },
      get () {
        return this.$root.RootUnreadNotifications
      }
    },
    AppNotificationsInterval: {
      set (v) {
        this.$root.RootNotificationsInterval = v
      },
      get () {
        return this.$root.RootNotificationsInterval
      }
    },
    FetchedAppNotifications: {
      set (v) {
        this.$root.FetchedRootNotifications = v
      },
      get () {
        return this.$root.FetchedRootNotifications
      }
    },
    FetchingAppNotifications: {
      set (v) {
        this.$root.FetchingRootNotifications = v
      },
      get () {
        return this.$root.FetchingRootNotifications
      }
    }
  }
}
