<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-form
    ref="dialogForm"
    v-slot="{invalid}"
    :errors.sync="formErrors"
  >
    <dialog-modal
      v-model="modal"
      max-width="960"
      no-click-animation
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          {{ $t('customerPage.account_statement') }}
          <v-spacer />
          <v-btn
            :disabled="loadingData"
            icon
            @click="closeDialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <slide-fade-transition>
            <v-container
              v-if="dialogItem"
              fluid
            >
              <v-row>
                <app-date-picker
                  v-model="itemForm.from_date"
                  cols="12"
                  name="from_date"
                  required
                  sm="6"
                />
                <app-date-picker
                  v-model="itemForm.to_date"
                  cols="12"
                  name="to_date"
                  required
                  sm="6"
                />
              </v-row>
            </v-container>
          </slide-fade-transition>
        </v-card-text>
        <v-card-actions>
          <app-btn
            :disabled="invalid"
            :loading="loadingData"
            color="success"
            @click="submit"
          >
            {{ $t('done') }}
          </app-btn>
        </v-card-actions>
      </v-card>
    </dialog-modal>
  </app-form>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import { GetHeadersMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'StatementDialog',
  components: { SlideFadeTransition, DialogModal },
  mixins: [GetHeadersMixin],
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modal: !1,
      dialogItem: undefined,
      loadingData: !1,
      formErrors: {},
      itemForm: {
        from_date: undefined,
        to_date: undefined
      }
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', { item: this.dialogItem, index: this.dialogIndex })
      }
    },
    loadingData: {
      deep: !0,
      handler (v) {
        this.$emit('update:loading', v)
      }
    },
    formErrors: {
      deep: !0,
      handler (v) {
        this.$emit('update:errors', v)
      }
    },
    itemForm: {
      deep: !0,
      handler (v) {
        this.$emit('update:form', v)
      }
    }
  },
  methods: {
    openDialog (item) {
      this.dialogItem = { ...item }
      this.itemForm = {
        from_date: this.$moment().locale('en').startOf('month').format('YYYY-MM-DD'),
        to_date: this.$moment().locale('en').endOf('month').format('YYYY-MM-DD')
      }
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
        this.itemForm = {}
        this.formErrors = {}
        this.$refs.dialogForm?.reset()
      })
    },
    async submit () {
      if (this.loadingData) return
      this.loadingData = !0
      this.formErrors = {}
      try {
        const params = { ...this.itemForm }
        const { _data } = await this.apiService.customer.statementUrl(this.dialogItem.id, { params: { from_date: params.from_date, to_date: params.to_date } })
        _data?.url && this.openWindow(_data.url)
      } catch ({ _message, _errors }) {
        _message && this.alertError(_message)
        this.formErrors = _errors || {}
      } finally {
        this.loadingData = !1
      }
    }
  }
}
</script>
