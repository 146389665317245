<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :form.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      @submitClickForm="submitClickForm"
    >
      <template #filter="{datatableFilters}">
        <v-container>
          <v-row>
            <app-mobile-input
              v-model="datatableFilters.mobile"
              clearable
              cols="12"
              name="mobile"
              sm="6"
            />
            <app-number-input
              v-model="datatableFilters.reservations_from"
              clearable
              cols="12"
              name="reservations_from"
              sm="6"
            />
            <app-number-input
              v-model="datatableFilters.reservations_to"
              clearable
              cols="12"
              name="reservations_to"
              sm="6"
            />
            <!--<app-classifications-->
            <!--  v-model="datatableFilters.classification_id"-->
            <!--  clearable-->
            <!--  cols="12"-->
            <!--  hide-details-->
            <!--  multiple-->
            <!--  name="classification_id"-->
            <!--  sm="6"-->
            <!--/>-->
            <app-filter-select
              v-model="datatableFilters.discount"
              clearable
              cols="12"
              name="discount"
              sm="6"
            />
            <app-filter-select
              v-model="datatableFilters.active"
              clearable
              cols="12"
              name="active"
              sm="6"
            />
          </v-row>
        </v-container>
      </template>

      <template #form="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-container>
            <v-row>
              <app-text-input
                v-model="selectedItem.name"
                cols="12"
                name="name"
                required
                sm="6"
              />
              <app-text-input
                v-model="selectedItem.mobile"
                cols="12"
                name="mobile"
                required
                rules="mobile"
                sm="6"
              />
              <app-text-input
                v-model="selectedItem.other_mobile"
                clearable
                cols="12"
                name="other_mobile"
                rules="mobile"
                sm="6"
              />
            </v-row>
            <v-row>
              <!--<app-classifications-->
              <!--  v-model="selectedItem.classification_id"-->
              <!--  clearable-->
              <!--  cols="12"-->
              <!--  name="classification_id"-->
              <!--  sm="6"-->
              <!--/>-->
              <app-text-input
                v-model="selectedItem.email"
                clearable
                cols="12"
                name="email"
                sm="6"
              />
              <app-text-input
                v-model="selectedItem.id_number"
                clearable
                cols="12"
                counter
                name="id_number"
                rules="digits:10"
                sm="6"
              />
              <app-text-input
                v-model="selectedItem.nationality"
                clearable
                cols="12"
                name="nationality"
                sm="6"
              />
            </v-row>
            <v-row>
              <app-switcher
                v-model="selectedItem.discount"
                cols="auto"
                name="discount"
              />
              <app-switcher
                v-model="selectedItem.active"
                cols="auto"
                name="active"
              />
            </v-row>
          </v-container>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('statementUrl')"
          :disabled="loadingDatatable"
          @click="openStatementDialog(item)"
        >
          <v-icon>
            list
          </v-icon>
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          :disabled="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
    <statement-dialog
      ref="statementDialog"
    />
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'
import StatementDialog from '@components/customer/StatementDialog'

export default {
  name: 'Index',
  components: { StatementDialog },
  helperApiName: 'customer',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Customer',
      headers: [
        'name',
        'mobile',
        {
          text: 'reservations_count',
          value: 'reservations_count',
          sortable: !1
        },
        'email',
        'id_number',
        'nationality',
        'other_mobile',
        'created_at_to_string',
        {
          text: 'status',
          value: 'active_to_string'
        },
        'discount_to_string',
        'control'
      ]
    }
  },
  computed: {
    defaultSelectedItem () {
      return {
        active: !0,
        discount: !0
      }
    }
  },
  mounted () {
    this.checkPermission('index')
  },
  methods: {
    openStatementDialog (item) {
      this.$refs.statementDialog.openDialog(item)
    },
    statementUrl (item) {
      if (this.loadingDatatable) return
      this.loadingDatatable = !0
      this.apiService.customer.statementUrl(item.id)
        .then(({ _data }) => {
          _data?.url && this.openWindow(_data.url)
        })
        .catch(e => {
        })
        .finally(() => (this.loadingDatatable = !1))
    }
  }
}
</script>
