<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-row>
    <app-users
      v-if="isAdminUser"
      v-model="form.user_id"
      :hint="$t('hints.payment_user_id')"
      cols="12"
      name="user_id"
      persistent-hint
      required
      sm="6"
    />
    <app-payment-methods
      v-model="form.payment_method_id"
      cols="12"
      name="payment_method_id"
      required
      sm="6"
    />
    <app-payment-types
      v-model="form.payment_type"
      cols="12"
      name="payment_type"
      required
      sm="6"
      @set-items="paymentTypes = $event"
    />
    <app-price-input
      v-model="form.amount"
      :suffix="$t('sar')"
      arules="isReservation && reservation && reservation.insurance_amount > 0 ? `max_value:${reservation.insurance_amount}` : undefined"
      cols="12"
      name="amount"
      required
      sm="6"
    />
    <app-date-picker
      v-model="form.payment_date"
      cols="12"
      name="payment_date"
      required
      sm="6"
    />
    <v-fade-transition>
      <app-payment-categories
        v-if="!isReservation"
        v-model="form.payment_category_id"
        clearable
        cols="12"
        name="payment_category_id"
        sm="6"
      />
    </v-fade-transition>
    <app-filter-select
      v-if="showInsurancePayment"
      v-model="form.insurance_payment"
      cols="12"
      name="insurance_payment"
      required
      sm="6"
    />
    <app-textarea-input
      v-if="!form.insurance_payment"
      v-model="form.description"
      cols="12"
      name="description"
      required
    />
  </v-row>
</template>

<script>

export default {
  name: 'PaymentForm',
  props: {
    reservation: {
      type: Object,
      default: () => undefined
    },
    form: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      paymentTypes: []
    }
  },
  computed: {
    isReservation () {
      // return this.paymentTypes.find(e => e.value === this.form.payment_type)?.value === 'withdraw' && this.reservation !== undefined
      return this.reservation !== undefined
    },
    showInsurancePayment () {
      if (!this.isReservation) {
        return !1
      }
      // return this.paymentTypes.find(e => e.value === this.form.payment_type)?.value === 'withdraw' && this.reservation !== undefined
      return Boolean(this.reservation?.insurance_amount)
    }
  },
  methods: {
    updateForm (v) {
      this.$emit('update:form', { ...this.form, ...v })
    }
  }
}
</script>
