<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-form
    ref="dialogForm"
    v-slot="{invalid}"
    :errors.sync="formErrors"
  >
    <dialog-modal
      v-model="modal"
      max-width="960"
      no-click-animation
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          {{ $t('reservationPage.complete') }}
          <v-spacer />
          <v-btn
            :disabled="loadingData"
            icon
            @click="closeDialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <slide-fade-transition>
            <v-container
              v-if="dialogItem"
              fluid
            >
              <v-row dense>
                <v-col
                  v-for="(e,i) in itemHeaders"
                  :key="i.toString()"
                  cols="12"
                  sm="4"
                >
                  <reservation-sheet
                    :text="e.text"
                    :value="dialogItem[e.value]"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('reservation_amount')"
                    :value="itemForm.reservation_amount_to_string"
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('discount')"
                    :value="itemForm.discount_to_string"
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('damages_amount')"
                    :value="$helpers.toNumberFormat(itemForm.damages_amount)"
                    color="success"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('services_amount')"
                    :value="$helpers.toNumberFormat(itemForm.services_amount)"
                    color="success"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('net_total')"
                    :value="itemForm.net_total_to_string"
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('insurance_amount')"
                    :value="itemForm.insurance_amount_to_string"
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('paid_amount')"
                    :value="itemForm.paid_amount_to_string"
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('refund_amount')"
                    :value="itemForm.refund_amount_to_string"
                    color="primary"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('total')"
                    :value="$helpers.toNumberFormat(itemTotal)"
                    color="success"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <reservation-sheet
                    :text="parseAttribute('rest_amount')"
                    :value="$helpers.toNumberFormat(restAmount)"
                    color="success"
                  />
                </v-col>
              </v-row>
              <v-row>
                <app-textarea-input
                  v-model="itemForm.damages"
                  :disabled="loadingData"
                  clearable
                  cols="12"
                  name="damages"
                  sm="6"
                />
                <app-price-input
                  v-model="itemForm.damages_amount"
                  :disabled="loadingData"
                  :suffix="$t('sar')"
                  clearable
                  cols="12"
                  name="damages_amount"
                  required
                  sm="6"
                />
              </v-row>
              <v-row>
                <app-textarea-input
                  v-model="itemForm.services"
                  :disabled="loadingData"
                  clearable
                  cols="12"
                  name="services"
                  sm="6"
                />
                <app-price-input
                  v-model="itemForm.services_amount"
                  :disabled="loadingData"
                  :suffix="$t('sar')"
                  clearable
                  cols="12"
                  name="services_amount"
                  required
                  sm="6"
                />
              </v-row>
              <!--<v-row dense>-->
              <!--<app-textarea-input-->
              <!--  v-model="itemForm.notes"-->
              <!--  :disabled="loadingData"-->
              <!--  clearable-->
              <!--  cols="12"-->
              <!--  sm="6"-->
              <!--  name="notes"-->
              <!--/>-->
              <!--</v-row>-->
              <v-row>
                <app-payment-methods
                  v-if="restAmount > 0"
                  v-model="itemForm.payment_method_id"
                  :hint="$t('hints.payment_method_id_complete')"
                  cols="12"
                  name="payment_method_id"
                  persistent-hint
                  required
                  sm="6"
                />
              </v-row>
              <v-row v-if="hasInsurancePaymentMethod">
                <app-payment-methods
                  v-model="itemForm.insurance_payment_method"
                  :required="itemForm.insurance_amount > 0"
                  cols="12"
                  name="insurance_payment_method"
                  sm="6"
                />
              </v-row>

              <!--
              <v-row
                v-if="itemForm.insurance_amount > 0"
                dense
              >
                <app-payment-methods
                  v-model="itemForm.insurance_payment_method"
                  :disabled="!itemForm.refund_insurance"
                  :required="itemForm.refund_insurance"
                  cols="12"
                  sm="6"
                  name="insurance_payment_method"
                />
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="itemForm.refund_insurance"
                        :label="parseAttribute('refund_insurance')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <app-payment-methods
                  v-model="itemForm.insurance_payment_method"
                  :disabled="!itemForm.refund_insurance"
                  :required="itemForm.refund_insurance"
                  cols="12"
                  sm="6"
                  name="insurance_payment_method"
                />
              </v-row>
              -->
            </v-container>
          </slide-fade-transition>
        </v-card-text>
        <v-card-actions>
          <app-btn
            :disabled="invalid"
            :loading="loadingData"
            color="success"
            @click="submit"
          >
            {{ $t('reservationPage.complete') }}
          </app-btn>
        </v-card-actions>
      </v-card>
    </dialog-modal>
  </app-form>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import ReservationSheet from '@components/reservation/ReservationSheet'
import { GetHeadersMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'CompleteReservationDialog',
  components: { SlideFadeTransition, ReservationSheet, DialogModal },
  mixins: [GetHeadersMixin],
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modal: !1,
      dialogItem: undefined,
      dialogIndex: undefined,
      loadingData: !1,
      formErrors: {},
      itemForm: {
        damages: undefined,
        damages_amount: undefined,
        notes: undefined,
        payment_method_id: undefined
      },
      itemHeaders: this.parseHeaders([
        // 'reservation_amount_to_string',
        // 'discount_to_string',
        // 'net_total_to_string',
        // 'insurance_amount_to_string',
        // 'services_amount_to_string',
        // 'damages_amount_to_string',
        // 'total_to_string',
        // 'paid_amount_to_string',
        // 'rest_amount_to_string',
        // 'payments_count'
      ])
    }
  },
  computed: {
    hasInsurancePaymentMethod () {
      const insuranceAmount = parseFloat(this.itemForm?.insurance_amount) || 0
      if (!insuranceAmount) {
        return !1
      }
      const refundAmount = parseFloat(this.itemForm?.refund_amount) || 0
      return insuranceAmount - refundAmount > 0 && !this.hideInsurancePaymentMethod
    },
    hideInsurancePaymentMethod () {
      const damagesAmount = parseFloat(this.itemForm?.damages_amount) || 0
      const servicesAmount = parseFloat(this.itemForm?.services_amount) || 0
      const extra = servicesAmount + damagesAmount
      const refundAmount = parseFloat(this.itemForm?.refund_amount) || 0
      const insuranceAmount = parseFloat(this.itemForm?.insurance_amount) || 0
      const rest = insuranceAmount - refundAmount
      return extra >= rest
    },
    itemTotal () {
      let total = 0
      if (this.itemForm) {
        const servicesAmount = parseFloat(this.itemForm.services_amount) || 0
        const damagesAmount = parseFloat(this.itemForm.damages_amount) || 0
        const insuranceAmount = parseFloat(this.itemForm.insurance_amount) || 0
        let reservationAmount = parseFloat(this.itemForm.reservation_amount) || 0
        let discount = parseFloat(this.itemForm.discount) || 0
        total = servicesAmount + damagesAmount + insuranceAmount
        if (discount > 0) {
          discount /= 100
          reservationAmount -= discount * reservationAmount
        }
        total += reservationAmount
      }
      return total
    },
    restAmount () {
      const paidAmount = parseFloat(this.dialogItem?.paid_amount) || 0
      const insuranceAmount = parseFloat(this.dialogItem?.insurance_amount) || 0
      return (this.itemTotal - paidAmount) - (this.itemForm.refund_insurance ? insuranceAmount : 0)
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', { item: this.dialogItem, index: this.dialogIndex })
      }
    },
    loadingData: {
      deep: !0,
      handler (v) {
        this.$emit('update:loading', v)
      }
    },
    formErrors: {
      deep: !0,
      handler (v) {
        this.$emit('update:errors', v)
      }
    },
    itemForm: {
      deep: !0,
      handler (v) {
        this.$emit('update:form', v)
      }
    }
  },
  methods: {
    openDialog (item, index) {
      this.dialogItem = { ...item }
      this.dialogIndex = index
      this.itemForm = { ...item }
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
        this.dialogIndex = undefined
        this.itemForm = {}
        this.formErrors = {}
        this.$refs.dialogForm?.reset()
      })
    },
    async submit () {
      if (this.loadingData) return
      this.confirmMessage(async () => {
        this.loadingData = !0
        this.formErrors = {}
        try {
          const form = { ...this.itemForm }
          // if (!form.refund_insurance) {
          //   form.refund_insurance = undefined
          //   form.insurance_payment_method = undefined
          // }

          const { _data, _message, _success } = await this.apiService.reservation.complete(this.dialogItem.id, form)
          if (_success === !0) {
            _message && this.alertSuccess(_message)
            _data && this.$emit('completed', { item: _data, index: this.dialogIndex })
            this.$nextTick(() => (this.closeDialog()))
          }
        } catch ({ _message, _errors }) {
          _message && this.alertError(_message)
          this.formErrors = _errors || {}
        } finally {
          this.loadingData = !1
        }
      })
    }
  }
}
</script>
