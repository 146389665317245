<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <auto-select
    id="customer_id"
    :hide-no-data="getHideNoData"
    :hint="$t('hints.search_on_customer')"
    :item-text="searchOptionData"
    :items="customersResult"
    :loading="loading"
    :no-data-text="$t('hints.customer_no_data_text')"
    :search-input.sync="search"
    :value="value"
    append-icon="search"
    class="auto--search-on-customer"
    cols="12"
    name="customer_id"
    persistent-hint
    sm="6"
    v-bind="$attrs"
    @blur="blurCustomer"
    @input="customerChange"
    v-on="$listeners"
  >
    <template #top>
      <div
        v-if="customerName"
        class="text-body-1 d-flex font-weight-black mb-2 align-center"
      >
        <span>{{ parseAttribute('customer_id') }}:&nbsp;</span>
        <span>{{ customerName }}</span>
        <v-spacer />
        <v-btn
          color="error"
          small
          @click="removeCustomer"
        >
          {{ $t('cancel') }}
        </v-btn>
      </div>
    </template>
    <!--    <template #append-outer>
      <AutoSelect
        v-model="searchOptionData"
        :items="searchOptions"
        :with-col="!1"
        name=""
        prepend-inner-icon="search"
        class="me-1"
      />
    </template>-->
  </auto-select>
</template>

<script>
import AutoSelect from '@components/base/inputs/AutoSelect'
import _ from 'lodash'
import { GetHeadersMixin } from '@mixins'

const events = {
  name: 'customerName',
  customer: 'customer'
}
export default {
  name: 'SearchOnCustomer',
  components: { AutoSelect },
  mixins: [GetHeadersMixin],
  props: {
    value: {
      type: [String, Number, null, undefined],
      default: () => undefined
    },
    customerName: {
      type: String,
      default: () => undefined
    },
    searchOption: {
      type: String,
      default: () => 'name_and_mobile'
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: !1,
      hideNoData: !0,
      search: null,
      customersResult: [],
      searchOptionData: ''
      // searchOptions: this.parseHeaders(['name', 'mobile', 'name_and_mobile'])
    }
  },
  computed: {
    getHideNoData () {
      if (this.loading) return !0
      return this.hideNoData
    }
  },
  watch: {
    search: {
      deep: !0,
      handler: _.debounce(function (n, o) {
        this.fetchCustomers()
      }, 500)
    }
  },
  mounted () {
    this.searchOptionData = this.searchOption
  },
  methods: {
    customerChange (v) {
      const customer = this.customersResult.find(e => parseInt(e.id) === parseInt(v))
      this.$emit(events.customer, customer)
      this.$emit(`update:${events.name}`, customer?.name || null)
    },
    fetchCustomers () {
      this.customersResult = []
      this.hideNoData = !0
      const search = this.search
      if (!search || search.length < 2) return
      const controller = new AbortController()
      this.loading && controller.abort()
      this.$nextTick(() => {
        this.loading = !0
        this.hideNoData = !1
        this.apiService.customer.staticUtilities({
          search,
          signal: controller.signal
        }).then(({ _data }) => {
          this.customersResult = _data || []
        }).catch((e) => e).finally(() => {
          this.loading = !1
        })
      })
    },
    removeCustomer () {
      this.customerChange(null)
      this.$emit('input', null)
      this.$emit('remove-customer', null)
    },
    blurCustomer () {
      this.customersResult = []
      this.hideNoData = !0
    }
  }
}
</script>
<style>
.auto--search-on-customer .v-input__prepend-outer {
  margin: 0 !important;
}
</style>
