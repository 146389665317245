/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { Stub, StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Reservation')
const Static = StubUrl('Static')
const Reservation = StubUrl(Static('Reservation'))

export default {
  ...Stub(url()),
  confirm: (id, form) => axios.post(url(`${id}/Confirm`), form),
  complete: (id, form) => axios.post(url(`${id}/Complete`), form),
  calendar: (params) => axios.get(url('Calendar'), { params }),
  payments: (id, params) => id === !0 ? url(`${params}/Payment`) : axios.get(url(`${id}/Payment`), { params }),
  addPayment: (id, form) => axios.post(url(`${id}/Payment`), form),
  contractUrl: id => axios.get(url(`${id}/Contract`)),
  updateDate: (id, form) => axios.post(url(`${id}/UpdateDate`), form),
  contractSigningUrl: (id, params) => axios.get(url(`${id}/ContractSigningUrl`), { params }),
  publicShow: (slug, params) => axios.get(Reservation(`${slug}/show`), { params }),
  saveSignature: (slug, signature) => axios.post(Reservation(`${slug}/SaveSignature`), { signature })
}
