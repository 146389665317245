<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :form.sync="dialog"
      :headers="headers"
      :item-class="itemClass"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      control-width="1%"
      pdf
      @submitClickForm="submitClickForm"
    >
      <template #top>
        <v-container v-show="!AppIsSmall">
          <v-row>
            <app-auto-select
              v-model="table.value"
              :dense="!1"
              :items="table.options"
              :label="$t('$datatable.table_headers')"
              append-outer-icon="refresh"
              chips
              cols="12"
              deletable-chips
              hide-selected
              multiple
              name="options"
              small-chips
              @click:append-outer="table.refresh"
            />
          </v-row>
        </v-container>
      </template>

      <template #filter="{datatableFilters}">
        <v-container>
          <v-row>
            <app-users
              v-if="isAdminUser"
              v-model="datatableFilters.user_id"
              clearable
              cols="12"
              name="user_id"
              sm="6"
            />
            <app-customers
              v-model="datatableFilters.customer_id"
              clearable
              cols="12"
              item-text="name_and_mobile"
              name="customer_id"
              sm="6"
            />
          </v-row>
          <v-row>
            <app-date-picker
              v-model="datatableFilters.from_start_date"
              cols="12"
              hide-details
              name="from_start_date"
              sm="6"
            />
            <app-date-picker
              v-model="datatableFilters.to_start_date"
              cols="12"
              hide-details
              name="to_start_date"
              sm="6"
            />
          </v-row>
          <v-row>
            <app-date-picker
              v-model="datatableFilters.from_end_date"
              cols="12"
              hide-details
              name="from_end_date"
              sm="6"
            />
            <app-date-picker
              v-model="datatableFilters.to_end_date"
              cols="12"
              hide-details
              name="to_end_date"
              sm="6"
            />
          </v-row>
          <v-row>
            <app-products
              v-model="datatableFilters.products_id"
              clearable
              cols="12"
              hide-details
              multiple
              name="products_id"
              sm="6"
            />
            <app-reservation-statuses
              v-model="datatableFilters.status"
              clearable
              cols="12"
              hide-details
              multiple
              name="status"
              sm="6"
            />
          </v-row>
          <!--<v-row>
            <app-text-input
              v-model="datatableFilters.name"
              clearable
              cols="12"
              name="name"
              sm="6"
            />
            <app-mobile-input
              v-model="datatableFilters.mobile"
              clearable
              cols="12"
              name="mobile"
              sm="6"
            />
          </v-row>-->
        </v-container>
      </template>

      <template #form="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <reservation-form
            :errors.sync="formErrors"
            :form.sync="selectedItem"
          />
        </app-form>
      </template>

      <template #actions>
        <app-btn
          :loading="formLoading"
          @click="submitClickForm"
        >
          {{ $t('save') }}
        </app-btn>
      </template>

      <template #item.control="{item,index}">
        <v-menu
          bottom
          offset-x
          offset-y
          right
        >
          <template #activator="{on,attr}">
            <v-btn
              icon
              v-bind="attr"
              v-on="on"
            >
              <v-icon>
                more_vert
              </v-icon>
            </v-btn>
          </template>
          <template #default>
            <v-list :dense="!AppIsSmall">
              <!--              <v-list-item
                v-if="userHasPermission('Payment.index')"
                link
                @click="openPaymentsDialog(item)"
              >
                <v-list-item-title>
                  <v-icon
                    right
                    color="success"
                  >
                    currency_exchange
                  </v-icon>
                  {{ $t('reservationPage.payments') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.is_completed === !1 && userHasPermission('Payment.store')"
                link
                @click="openPaymentDialog(item,index)"
              >
                <v-list-item-title>
                  <v-icon
                    right
                    color="success"
                  >
                    attach_money
                  </v-icon>
                  {{ $t('reservationPage.add_payment') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.can_confirm === !0 && userHasPermission('confirm')"
                link
                @click="openConfirmDialog(item,index)"
              >
                <v-list-item-title>
                  <v-icon
                    right
                    color="success"
                  >
                    check
                  </v-icon>
                  {{ $t('reservationPage.confirm') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="item.can_complete === !0 && userHasPermission('complete')"
                link
                @click="openCompleteDialog(item,index)"
              >
                <v-list-item-title>
                  <v-icon
                    right
                    color="success"
                  >
                    payments
                  </v-icon>
                  {{ $t('reservationPage.complete') }}
                </v-list-item-title>
              </v-list-item>-->
              <v-list-item
                v-if="userHasPermission('show')"
                link
                @click="openReservationDialog(item,index)"
              >
                <v-list-item-title v-text="$t('show')" />
              </v-list-item>
              <v-list-item
                v-if="userHasPermission('update')"
                link
                @click="showUpdateDialog(item)"
              >
                <v-list-item-title v-text="$t('update')" />
              </v-list-item>
              <v-list-item
                v-if="userHasPermission('destroy')"
                link
                @click="deleteItem(item)"
              >
                <v-list-item-title v-text="$t('destroy')" />
              </v-list-item>
            </v-list>
          </template>
        </v-menu>
      </template>
    </app-datatable>
    <show-reservation
      ref="reservationDialog"
      @refresh="refreshData($event)"
    />
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'
import ReservationForm from '@components/reservation/ReservationForm'
import ShowReservation from '@components/reservation/ShowReservation'
import SearchOnCustomer from '@components/base/search/SearchOnCustomer'

const getDefaultHeaders = () => ([
  'reservation_key',
  {
    text: 'name',
    value: 'name',
    width: 100
  },
  {
    text: 'mobile',
    value: 'mobile',
    width: 100
  },
  {
    text: 'customer_id_to_string',
    value: 'customer_id_to_string',
    width: 100
  },
  {
    text: 'products_id_to_string',
    value: 'products_id_to_string',
    sortable: !1
  },
  {
    text: 'date',
    value: 'date_to_string',
    sortable: !0,
    width: 200
  },
  'updates_count',
  'reservation_amount_to_string',
  'insurance_amount_to_string',
  'discount_to_string',
  'services_amount_to_string',
  'damages_amount_to_string',
  'status_to_string',
  {
    text: 'total',
    value: 'total_to_string',
    sortable: !1
  },
  {
    text: 'paid_amount',
    value: 'paid_amount_to_string',
    sortable: !1
  },
  {
    text: 'rest_amount',
    value: 'rest_amount_to_string',
    sortable: !1
  },
  'payments_count'
])

export default {
  name: 'Index',
  components: { SearchOnCustomer, ShowReservation, ReservationForm },
  helperApiName: 'reservation',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Reservation',
      table: {
        value: [...getDefaultHeaders()],
        options: this.parseHeaders([
          ...getDefaultHeaders(),
          'id_number',
          'nationality',
          'other_mobile',
          'created_at_to_string',
          'from_date',
          'to_date',
          'reservation_key'
        ]),
        refresh: () => (this.table.value = [...getDefaultHeaders()])
      }
    }
  },
  computed: {
    headers () {
      return [
        'control',
        (this.isAdminUser ? 'user_id_to_string' : null),
        ...this.table.value,
        (!this.AppIsSmall ? 'control' : null)
      ]
    }
  },
  mounted () {
    this.checkPermission('index')
  },
  methods: {
    itemClass (item) {
      if (item.is_new === !0) {
        return `yellow ${this.themeDark ? 'black--text' : 'accent-1'}`
      }

      if (item.is_confirmed === !0) {
        return 'white--text success lighten-1'
      }

      // if(item.is_completed === !0){
      // return 'blue'
      // }
      // console.log(item)
      return ''
    },
    // openConfirmDialog (item, index) {
    //   this.$refs.confirmDialog.openDialog(item, index)
    // },
    // openCompleteDialog (item, index) {
    //   this.$refs.completeDialog.openDialog(item, index)
    // },
    // openPaymentsDialog (item) {
    //   this.$refs.paymentsDialog.openDialog(item)
    // },
    // openPaymentDialog (item, index) {
    //   this.$refs.paymentDialog.openDialog(item, index)
    // },
    openReservationDialog (item, index) {
      this.$refs.reservationDialog.openShowDialog(item, index)
    },
    refreshData ({ item, index }) {
      this.refreshDatatable(item, index)
    }
  }
}
</script>
