<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <app-datatable
      ref="datatable"
      :btn-dialog="userHasPermission('store')"
      :form-loading.sync="formLoading"
      :form.sync="dialog"
      :headers="headers"
      :modal-title="datatableDialogTitle"
      :url="tableUrl"
      pdf
      @submitClickForm="submitClickForm"
    >
      <template #filter="{datatableFilters}">
        <v-container>
          <v-row>
            <search-on-customer
              v-model="datatableFilters.customer_id"
              :customer-name.sync="datatableFilters.customer_id_to_string"
              clearable
              search-option="name_and_mobile"
            />
            <app-text-input
              v-model="datatableFilters.reservation_key"
              clearable
              cols="12"
              name="reservation_key"
              sm="6"
            />

            <app-payment-methods
              v-model="datatableFilters.payment_method_id"
              clearable
              cols="12"
              multiple
              name="payment_method_id"
              sm="6"
            />
            <app-payment-types
              v-model="datatableFilters.payment_type"
              clearable
              cols="12"
              multiple
              name="payment_type"
              sm="6"
            />
            <app-payment-categories
              v-model="datatableFilters.payment_category_id"
              clearable
              cols="12"
              multiple
              name="payment_category_id"
              sm="6"
            />
            <app-filter-select
              v-model="datatableFilters.insurance_payment"
              clearable
              cols="12"
              name="insurance_payment"
              sm="6"
            />
          </v-row>
          <v-row>
            <app-date-picker
              v-model="datatableFilters.from_payment_date"
              cols="12"
              name="from_payment_date"
              sm="6"
            />
            <app-date-picker
              v-model="datatableFilters.to_payment_date"
              cols="12"
              name="to_payment_date"
              sm="6"
            />
          </v-row>
          <v-row>
            <app-date-picker
              v-model="datatableFilters.from_created_at"
              cols="12"
              name="from_created_at"
              sm="6"
            />
            <app-date-picker
              v-model="datatableFilters.to_created_at"
              cols="12"
              name="to_created_at"
              sm="6"
            />
          </v-row>
        </v-container>
      </template>

      <template #form="props">
        <app-form
          ref="form"
          :errors="formErrors"
          :submit="submitForm"
        >
          <v-container>
            <payment-form :form="selectedItem" />
          </v-container>
        </app-form>
      </template>

      <template #item.control="{item}">
        <app-dt-btn
          v-if="userHasPermission('show') && item.print_url"
          @click="openWindow(item.print_url)"
        >
          <v-icon>print</v-icon>
        </app-dt-btn>
        <app-dt-btn
          v-if="userHasPermission('update')"
          :disabled="loadingDatatable"
          update
          @click="showUpdateDialog(item)"
        />
        <app-dt-btn
          v-if="userHasPermission('destroy')"
          :disabled="loadingDatatable"
          destroy
          @click="deleteItem(item)"
        />
      </template>
    </app-datatable>
  </app-card>
</template>
<script>
import { DatatableMixin, GetHeadersMixin, MetaInfoMixin } from '@mixins'
import PaymentForm from '@components/payment/PaymentForm'
import SearchOnCustomer from '@components/base/search/SearchOnCustomer'

export default {
  name: 'Payments',
  components: { SearchOnCustomer, PaymentForm },
  helperApiName: 'payment',
  mixins: [MetaInfoMixin, DatatableMixin, GetHeadersMixin],
  data () {
    return {
      modelPermission: 'Payment',
      headers: [
        'reservation_key',
        'payment_method_id_to_string',
        'payment_category_id_to_string',
        'payment_type_to_string',
        'amount_to_string',
        'description',
        'payment_date_to_string',
        'insurance_payment_to_string',
        'created_at_to_string',
        'control'
      ]
    }
  },
  mounted () {
    this.checkPermission('index')
  }
}
</script>
