/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import Auth from '@helpers/Auth'
import config from '@app/config'
import Api from '@app/api'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    ...Auth.default,
    appReady: false,
    dark: !1
  },
  getters: {
    isLogin: s => s.token && s.token.length > 0 && s.user ? !0 : !1,
    getToken: s => s.token,
    getUser: s => s.user,
    getAppReady: s => s.appReady,
    getUserRole: s => s.user[Auth.role_key] ? s.user[Auth.role_key].toLocaleLowerCase().trim() : '',
    themeDark: s => s.dark
  },
  actions: {
    /**
     *
     * @param commit
     * @param dispatch
     * @param token
     * @param user
     * @param reload
     * @param url
     */
    login ({ commit, dispatch }, { token = null, user = null, reload = !1, url = null }) {
      token = token || Auth.default.token

      /* Save locale storage */
      Auth.save({ token })
      commit('setToken', token)
      // commit('setUser', user)
      dispatch('setUser', user)

      if (url && window) {
        // console.log(url)
        window.location.href = url
        return
      }
      // reload === true && window && window.location.reload()
      // reload === true && window && (window.location.href = window.location)
      reload === true && window && (window.location.href = '/')

      if (!url && !reload) {
        dispatch('refreshUser')
      }
    },

    /**
     *
     * Action logout
     * @param commit
     * @param reload
     * @param url
     */
    logout ({ commit }, { reload, url }) {
      // console.log (reload)
      // return
      /** remove locale storage */
      Auth.logout()
      const { token, user } = Auth.default
      commit('setToken', token)
      commit('setUser', user)

      if (url && window) {
        window.location.href = url
        return
      }
      reload === true && window && (window.location.href = '/')
    },

    /**
     * Action refresh state from localstorage
     * @param commit
     * @param dispatch
     * @param callback
     */
    async refreshUser ({ commit, dispatch }, callback) {
      let { token } = Auth.data() || {}
      token = token || Auth.default.token

      commit('setToken', token)

      if (!token) {
        // commit('setAppReady', !0)
      } else {
        try {
          const { _data } = await Api.methods.auth.checkToken()
          // console.log(_data.locale)
          // commit('setUser', _data)
          try {
            await dispatch('setUser', _data)
          } catch (e) {

          }
        } catch (error) {
          const { response } = error || {}
          const status = response?.status

          // Not authorized
          if (status === 401) {
            dispatch('logout', { reload: !0 })
          }
        } finally {
          dispatch('sideMenu/fetchItems', {}, { root: !0 })
          if (typeof callback === 'function') {
            callback()
          }
          setTimeout(() => {
            commit('setAppReady', !0)
          }, 90)
        }
      }
    },

    setUser ({ commit }, user) {
      Auth.save(user)
      commit('setUser', user)
    },

    setThemeDark ({ commit }, { value }) {
      localStorage.setItem(config.theme.storageKey, JSON.stringify({ value }))
      commit('setDark', value)
    },

    async iniAuthFromStorage ({ state, dispatch }, callback) {
      // if (!state.appReady) {
      // }
      // console.log('Ini Auth')
      await dispatch('refreshUser', callback)
      return Auth.isLogin()
    },

    setAppReady ({ commit }, value) {
      // console.log('setAppReady', value)
      commit('setAppReady', value)
    }
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
      axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : undefined
    },
    setUser: (state, user) => (state.user = user),
    setDark: (state, dark) => (state.dark = dark),
    setAppReady: (state, value) => (state.appReady = Boolean(value))
  }
}
