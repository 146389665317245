<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-sheet
    v-if="userHasPermission('calendar')"
    width="100%"
  >
    <v-sheet
      class="d-flex justify-space-between align-center"
      height="60"
    >
      <v-btn
        :disabled="loading"
        class="ma-2"
        icon
        @click="$refs.calendar.prev()"
      >
        <v-icon>{{ `mdi-chevron-${AppAlign}` }}</v-icon>
      </v-btn>
      <div v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </div>
      <!--<v-sheet width="190">-->
      <!--  <app-text-input-->
      <!--    v-model="customerSearch"-->
      <!--    :loading="loading"-->
      <!--    :with-col="!1"-->
      <!--    dense-->
      <!--    hide-details-->
      <!--    name="customer_id"-->
      <!--    clearable-->
      <!--  />-->
      <!--</v-sheet>-->
      <v-sheet width="190">
        <app-products
          v-model="product_id"
          :disabled="loading"
          :with-col="!1"
          clearable
          dense
          name="product_id"
        />
      </v-sheet>
      <!--<v-sheet width="100">-->
      <!--  <app-auto-select-->
      <!--    v-model="type"-->
      <!--    :disabled="loading"-->
      <!--    :items="types"-->
      <!--    :with-col="!1"-->
      <!--    dense-->
      <!--    hide-details-->
      <!--    name="type"-->
      <!--    outlined-->
      <!--  />-->
      <!--</v-sheet>-->
      <!--<app-date-picker-->
      <!--  v-model="value"-->
      <!--  :disabled="loading"-->
      <!--  :with-col="!1"-->
      <!--  dense-->
      <!--  hide-details-->
      <!--  name="date"-->
      <!--/>-->
      <!--<div class="text-h6 px-5">-->
      <!--  {{ $tc('choice.Reservations', 2) }}: ({{ events.length }})-->
      <!--</div>-->
      <v-sheet
        class="align-self-center"
        height="35"
        width="35"
      >
        <v-progress-circular
          v-show="loading"
          color="primary"
          indeterminate
        />
      </v-sheet>
      <v-btn
        :disabled="loading"
        class="ma-2"
        icon
        @click="$refs.calendar.next()"
      >
        <v-icon>{{ `mdi-chevron-${AppAlignReverse}` }}</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet
      height="700"
    >
      <v-calendar
        ref="calendar"
        v-model="value"
        :event-color="getEventColor"
        :event-overlap-threshold="30"
        :events="events"
        :locale="AppLocale"
        :type="type"
        color="primary"
        event-name="event_name"
        event-overlap-mode="stack"
        event-timed="event_timed"
        @change="fetchEvents"
        @contextmenu:day="showDate"
        @click:event="showEvent"
        @click:more="showMore"
      />
    </v-sheet>
    <dialog-modal
      v-model="reservationDialog.value"
      no-click-animation
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>{{ reservationDate }}</v-card-title>
        <v-card-text>
          <app-form
            ref="form"
            :errors="formErrors"
            :submit="submitForm"
          >
            <reservation-calendar-form
              :date.sync="reservationDialog.date"
              :form.sync="form"
              :product.sync="product_id"
            />
            <button
              ref="submitForm"
              class="d-none"
              type="submit"
            />
          </app-form>
        </v-card-text>
        <v-card-actions>
          <app-btn
            :loading="loadingForm"
            color="success"
            @click="submitClick"
          >
            {{ $t('save') }}
          </app-btn>
          <v-spacer />
          <v-btn
            :disabled="loadingForm"
            color="error"
            @click="reservationDialog.value = !1"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </dialog-modal>
    <show-reservation
      ref="reservationDialog"
      @refresh="fetchEvents()"
    />
    <reservations-dialog
      ref="reservationsDialog"
      @click:reservation="onClickReservation"
    />
  </v-sheet>
</template>

<script>
import DialogModal from '@components/base/grid/DialogModal'
import ReservationCalendarForm from '@components/reservation/ReservationCalendarForm'
import _ from 'lodash'
import { Tools } from '@helpers/Tools'
import ShowReservation from '@components/reservation/ShowReservation'
import ReservationsDialog from '@components/reservation/ReservationsDialog'

export default {
  name: 'ReservationCalendar',
  components: { ReservationsDialog, ShowReservation, ReservationCalendarForm, DialogModal },
  data () {
    return {
      modelPermission: 'Reservation',
      reservationDialog: {
        value: !1,
        date: null
      },
      loading: !1,
      type: 'month',
      types: [
        {
          text: this.$t('month'),
          value: 'month'
        },
        {
          text: this.$t('week'),
          value: 'week'
        }
      ],
      mode: 'column',
      value: this.$moment(new Date()).locale('en').format('YYYY-MM-DD'),
      events: [],
      eventsDate: {},
      product_id: undefined,
      form: {},
      formErrors: {},
      loadingForm: !1,
      customerSearch: undefined
    }
  },
  computed: {
    reservationDate () {
      if (!this.reservationDialog.date) return ''
      const date = this.$moment(this.reservationDialog.date)
      const month = date.format('MMMM')
      const day = date.format('dddd')
      return `${day} - ${date.locale('en').format('DD')} ${month} ${date.locale('en').format('YYYY')}`
    }
  },
  watch: {
    'reservationDialog.value' (v) {
      if (v === !1) {
        this.reservationDialog.date = null
        this.$refs.form?.reset()
        this.formErrors = {}
        this.form = {}
      }
    },
    product_id: {
      deep: !0,
      handler: _.debounce(function (n, o) {
        this.fetchEvents()
      }, 300)
    },
    customerSearch: {
      deep: !0,
      handler: _.debounce(function (n, o) {
        this.fetchEvents()
      }, 300)
    }
  },
  methods: {
    showEvent (event, e) {
      e.preventDefault()
      if (event.event) {
        this.$refs.reservationDialog?.openShowDialog(event.event)
        return
      }

      if (event.date) {
        // this.showDate(event)
      }
      // console.log(event)
    },
    showDate ({ date }) {
      if (this.loading || !this.userHasPermission('Reservation.store', !1)) return
      this.form.products_id = this.product_id
      this.reservationDialog.date = date
      this.$nextTick(() => (this.reservationDialog.value = !0))
    },
    async fetchEvents (date) {
      if (this.loading) return
      this.loading = !0
      let events = []
      date && (this.eventsDate = date)
      this.$nextTick(async () => {
        const { start, end } = this.eventsDate

        try {
          const { _data } = await this.apiService.reservation.calendar({
            start_date: start.date,
            end_date: end.date,
            customer_search: this.customerSearch,
            filter: {
              products_id: this.product_id
            }
          })
          if (_data) {
            events = _data
          }
        } catch (e) {
        } finally {
          this.events = events
          this.loading = !1
        }
        // console.log(events[0])
        this.$nextTick(() => (this.events = events))
      })
    },
    getEventColor (event) {
      return event?.event_color || 'primary'
    },
    submitClick () {
      this.$refs.submitForm.click()
    },
    async submitForm () {
      if (this.loadingForm) return
      this.loadingForm = !0
      try {
        const form = { ...this.form }
        if (form.products_id && !Tools.isOnlyArray(form.products_id)) {
          form.products_id = [form.products_id]
        }
        const { _success, _message } = await this.apiService.reservation.store(form)
        if (_success === !0) {
          _message && this.alertSuccess(_message)
          this.$nextTick(async () => (await this.fetchEvents()))
          this.$nextTick(() => (this.reservationDialog.value = !1))
        }
      } catch ({ _message, _errors }) {
        this.formErrors = _errors || {}
        _message && this.alertError(_message)
      } finally {
        this.loadingForm = !1
      }
    },
    showMore (e) {
      this.$refs.reservationsDialog.openDialog(e?.date)
    },
    onClickReservation (e) {
      e?.item && this.$refs.reservationDialog.openShowDialog(e.item)
    }
  }
}
</script>
