<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-form
    ref="dialogForm"
    v-slot="{invalid}"
    :errors.sync="formErrors"
    :submit="submit"
  >
    <dialog-modal
      v-model="modal"
      no-click-animation
      persistent
      scrollable
    >
      <slide-fade-transition>
        <v-card v-if="dialogItem">
          <v-card-title>
            {{ $t('reservationPage.update_date') }} ({{ parseAttribute('updates_count') }}: {{ dialogItem.updates_count }})
            <v-spacer />
            <v-btn
              :disabled="loadingData"
              icon
              @click="closeDialog"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <app-date-picker
                  v-model="itemForm.from_date"
                  cols="12"
                  name="from_date"
                  required
                  sm="6"
                />
                <app-date-picker
                  v-model="itemForm.to_date"
                  clearable
                  cols="12"
                  name="to_date"
                  sm="6"
                />
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <app-submit
              :disabled="invalid || $_.isEqual(dialogItem,itemForm)"
              :loading="loadingData"
              color="success"
              @click="submit"
            >
              {{ $t('save') }}
            </app-submit>
          </v-card-actions>
        </v-card>
      </slide-fade-transition>
    </dialog-modal>
  </app-form>
</template>

<script>

import DialogModal from '@components/base/grid/DialogModal'
import SlideFadeTransition from '@components/SlideFadeTransition'

export default {
  name: 'UpdateDateReservationDialog',
  components: { SlideFadeTransition, DialogModal },
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: () => !1
    }
  },
  data () {
    return {
      modal: !1,
      dialogItem: undefined,
      dialogIndex: undefined,
      loadingData: !1,
      formErrors: {},
      itemForm: {}
    }
  },
  watch: {
    modal: {
      deep: !0,
      handler (v) {
        this.$emit('input', v)
        this.$emit(v === !0 ? 'open' : 'close', { item: this.dialogItem, index: this.dialogIndex })
      }
    },
    loadingData: {
      deep: !0,
      handler (v) {
        this.$emit('update:loading', v)
      }
    },
    formErrors: {
      deep: !0,
      handler (v) {
        this.$emit('update:errors', v)
      }
    },
    itemForm: {
      deep: !0,
      handler (v) {
        this.$emit('update:form', v)
      }
    }
  },
  mounted () {
    // console.log(213)
  },
  methods: {
    openDialog (item, index) {
      this.dialogItem = item
      this.dialogIndex = index
      item && (this.itemForm = { ...item })
      this.$nextTick(() => (this.modal = !0))
    },
    closeDialog () {
      this.modal = !1
      this.$nextTick(() => {
        this.dialogItem = undefined
        this.dialogIndex = undefined
        this.itemForm = {}
        this.formErrors = {}
        this.$refs.dialogForm?.reset()
      })
    },
    async submit () {
      if (this.loadingData) return
      this.confirmMessage(async () => {
        this.loadingData = !0
        this.formErrors = {}
        try {
          const form = { ...this.itemForm }
          const { _data, _message, _success } = await this.apiService.reservation.updateDate(this.dialogItem.id, form)
          if (_success === !0) {
            _message && this.alertSuccess(_message)
            _data && this.$emit('saved', { item: _data, index: this.dialogIndex })
            this.$nextTick(() => (this.closeDialog()))
          }
        } catch ({ _message, _errors }) {
          _message && this.alertError(_message)
          this.formErrors = _errors || {}
        } finally {
          this.loadingData = !1
        }
      })
    }
  }
}
</script>
