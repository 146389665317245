/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './register'
import Auth from '@helpers/Auth'
import { APP_ROUTES } from '@routes/list'

Vue.use(VueRouter)
const options = {
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    const behavior = 'smooth'
    // console.log(this)
    const scrollTo = 0
    const options = {
      duration: 700,
      offset: 50
    }
    const scroll = savedPosition || { x: 0, y: 0 }

    if (to?.hash) scroll.selector = to.hash

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // console.log (scroll)
        resolve({ ...scroll, behavior })
      }, 50)
    })
    // return goTo (to.hash ? to.hash : (savedPosition ? savedPosition.y : scrollTo), options)
  }
}

const router = new VueRouter(options)

router.beforeResolve((to, from, next) => {
  // console.log(router.app.$root.startProgress)
  router.app.$root.startProgress()
  next()
})

router.afterEach(() => {
  setTimeout(() => router.app.$root.stopProgress(), 50)
})

router.beforeEach((to, from, next) => {
  // console.log(to,from)
  // return;

  let name
  if (to.matched.some(r => r.meta.auth) && !Auth.isLogin()) {
    name = APP_ROUTES.auth.login
    next({ name })
    return
  } else if (to.matched.some(r => r.meta.guest)) {
    if (Auth.isLogin() && from?.name === APP_ROUTES.homePage) {
      next()
      return
    }

    if (Auth.isLogin()) {
      name = APP_ROUTES.user.home
      next({ name })
      return
    }
  }
  next()
})
export default router
