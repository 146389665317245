/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  Auths: 'المصادقة|المصادقة',
  SideMenus: 'القائمة الجانبية|القائمة الجانبية',
  Ages: '{0}-|[1]سنة|[2]سنتان|[3,10]:age سنوات|[11,*] :age سنة',
  Days: '{0}-|[1]يوم|[2]يومان|[3,10]:days أيام|[11,*]:days يوم',
  Minutes: '{0}-|[1]دقيقة|[2]دقيقتان|[3,10]:minutes دقائق|[11,*] :minutes دقيقة',
  Hours: '{0}-|[1] ساعة|[2] ساعتان|[3,10]:hours ساعات|[11,*]:hours ساعة',
  Months: '{0}-|[1] شهر واحد|[2] شهرين|[3,10]:months أشهر|[11,*]:months شهر',
  MediaFiles: 'المرفق|المرفقات',
  Attachments: 'المرفق|المرفقات',
  Permissions: 'الصلاحية|الصلاحيات',
  Roles: 'الوظيفة|الوظائف',
  Users: 'المستخدم|المستخدمون',
  Utilities: 'أداة|أدوات',
  Notifications: 'الإشعار|الإشعارات',
  Settings: 'الإعداد|الإعدادات',
  PaymentMethods: 'طريقة الدفع|طرق الدفع',
  ContractTerms: 'الشروط والأحكام|الشروط والأحكام',
  Customers: 'العميل|العملاء',
  Classifications: 'التصنيف|التصنيفات',
  Discounts: 'الخصم|الخصومات',
  Products: 'المنتجع|المنتجعات',
  Payments: 'الدفعة|الدفعات',
  Reservations: 'الحجز|الحجوزات',
  Reports: 'التقرير|التقارير',
  ReservationsReports: 'تقرير الحجوزات|تقرير الحجوزات',
  PaymentsReports: 'تقرير الدفعات|تقرير الدفعات',
  PaymentCategories: 'فئة الدفعة|فئات الدفعات',
  PaymentGroups: 'مجموعة الدفعات|مجموعات الدفعات',
  Incomes: 'الإيراد|الإيرادات',
  Expenses: 'المصروف|المصروفات'
}
