/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  id: 'ID',
  login_id: 'Login ID',
  user_id: 'User',
  roles_id: 'Roles',
  permission_id: 'Permission',
  permissions_id: 'Permissions',
  role_id: 'Role',
  payment_method_id: 'Payment Method',
  payment_id: 'Payment',
  invoice_id: 'Invoice',
  contract_term_id: 'Contract Term',
  classification_id: 'Classification',
  classifications_id: 'Classifications',
  reservation_id: 'Reservation',
  customer_id: 'Customer',
  product_id: 'Chalet',
  products_id: 'Chalets',
  payment_category_id: 'Payment Category',
  payment_categories_id: 'Payment Categories',
  username: 'Username',
  name: 'Name',
  name_ar: 'Arabic Name',
  name_en: 'English Name',
  password: 'Password',
  password_confirmation: 'Confirm Password',
  email: 'Email',
  mobile: 'Mobile',
  phone: 'Phone',
  whatsapp: 'Whatsapp',
  code: 'Code',
  two_factor_auth: 'Two Factor Auth',
  date: 'Date',
  from_date: 'From date',
  to_date: 'To date',
  description: 'Description',
  description_ar: 'Arabic Description',
  description_en: 'English Description',
  active: 'Active',
  token: 'Token',
  type: 'Type',
  status: 'Status',
  push_token: 'Device push token',
  verification_code: 'Verification Code',
  attachment: 'Attachment',
  attachments: 'Attachments',
  send_time: 'Send Time',
  locale: 'Locale',
  app_name: 'App name',
  app_name_ar: 'Arabic App name',
  app_name_en: 'English App name',
  notification_email: 'Email notifications',
  notification_mobile: 'Mobile notifications',
  web: 'Website url',
  twitter: 'Twitter',
  instagram: 'Instagram',
  snapchat: 'Snapchat',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  customer_service_email: 'Customer service email',
  customer_service_mobile: 'Customer service mobile',
  customer_service_phone: 'Customer service phone',
  google_map_url: 'google map url',
  app_store_url: 'App Store url',
  play_store_url: 'Play Store url',
  address: 'Address',
  address_ar: 'Arabic address',
  address_en: 'English address',
  contact_us: 'Contact us',
  contact_us_ar: 'Arabic Contact us',
  contact_us_en: 'English Contact us',
  about_us: 'About us',
  about_us_ar: 'Arabic About us',
  about_us_en: 'English About us',
  privacy_policy: 'Privacy & Policy',
  privacy_policy_ar: 'Arabic Privacy & Policy',
  privacy_policy_en: 'English Privacy & Policy',
  terms_and_conditions: 'Terms & Conditions',
  terms_and_conditions_ar: 'Arabic Terms & Conditions',
  terms_and_conditions_en: 'English Terms & Conditions',
  commercial_register: 'Commercial Register',
  commercial_name: 'Commercial name',
  commercial_name_ar: 'Arabic Commercial name',
  commercial_name_en: 'English Commercial name',
  vat_number: 'VAT number',
  notification_methods: 'Notification Methods',
  avatar: 'Avatar',
  order_by: 'Order by',
  key: 'Key',
  created_at: 'Created date',
  from_created_at: 'From Created At',
  to_created_at: 'To Created At',
  message: 'Your message',
  price: 'Price',
  notes: 'Notes',
  icon: 'Icon',
  subject: 'Subject',
  image: 'Image',
  images: 'Images',
  choose_image: 'Choose Image',
  update_image: 'Update Image',
  blobAvatar: 'Avatar',
  url: 'Url',
  amount: 'Amount',
  discount: 'Discount',
  discount_type: 'Discount Type',
  discount_amount: 'Discount Amount',
  meta: 'Meta',
  quantity: 'Quantity',
  location: 'Location',
  tax: 'TAX',
  payable_id: 'Payable ID',
  payable_type: 'Payable Type',
  total: 'Total',
  data: 'Data',
  from_discount_amount: 'Discount Amount From',
  to_discount_amount: 'Discount Amount To',
  expire_date: 'Expire Date',
  from_expire_date: 'Expire Date From',
  to_expire_date: 'Expire Date To',
  read: 'read',
  ref_key: 'Reference ID',
  attachment_type: 'Attachment Type',
  percentage: 'Percentage',
  vat: 'Vat',
  vat_amount: 'Vat Amount',
  payment_date: 'Payment Date',
  from_payment_date: 'Payment Date From',
  to_payment_date: 'Payment Date To',
  payment_type: 'Payment Type',
  paid_amount: 'Paid Amount',
  color: 'Color',
  symbol: 'Symbol',
  contact: 'Contact',
  start_date: 'Start Date',
  from_start_date: 'From Start_date',
  to_start_date: 'To_start Date',
  end_date: 'End_date',
  from_end_date: 'From End_date',
  to_end_date: 'To_end Date',
  start_time: 'Start Time',
  from_start_time: 'From Start_time',
  to_start_time: 'To_start Time',
  end_time: 'End_time',
  from_end_time: 'From End_time',
  to_end_time: 'To_end Time',
  database: 'App',
  mail: 'E-mail',
  sms: 'SMS',
  pay_date: 'Pay Date',
  from_pay_date: 'From Pay Date',
  to_pay_date: 'To Pay Date',
  due_amount: 'Due Amount',
  customer_name: 'Customer Name',
  signature: 'Signature',
  stamp: 'Stamp',
  reservations_count: 'Reservations Count',
  reservations_from: 'Reservations From',
  reservations_to: 'Reservations To',
  classifications: 'Classifications',
  reservation_update_limit: 'limit of update reservation',
  reservation_amount: 'Reservation Amount',
  insurance_amount: 'Insurance Amount',
  services: 'Services',
  services_amount: 'Services Amount',
  damages: 'Damages',
  damages_amount: 'Damages Amount',
  contract_number: 'Contract No.',
  reservation_key: 'Reservation ID',
  updates_count: 'Updates Count',
  id_number: 'ID Number',
  customer_code: 'Customer Code',
  down_payment: 'Down Payment',
  rest_amount: 'Rest Amount',
  new_reservation_period: 'New Reservation Period',
  payments_count: 'Payments Count',
  name_and_mobile: 'Name & Mobile',
  refund_insurance: 'Refund Insurance',
  insurance_payment_method: 'Insurance payment method',
  permit_no: 'Permit No.',
  tenant_name: 'Tenant name',
  contract_line_ar: 'Appointments in Arabic',
  contract_line_en: 'Appointments in English',
  nationality: 'Nationality',
  reservation_start_hour: 'Reservation start hour',
  reservation_hours: 'Reservation hours per day',
  signing_date: 'Signing Date',
  other_mobile: 'Other Mobile',
  net_total: 'Net Total',
  refund_amount: 'Refund Amount',
  insurance_amount_of_reservation: 'Insurance Amount Of Reservation',
  profit_loss_report: 'Profit & loss',
  insurance_payment: 'Insurance Payment',
  payment_group_id: 'Payment Group',
  payment_groups_id: 'Payment Groups',
  deposit: 'Deposit',
  withdraw: 'Withdraw'
}
