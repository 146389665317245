/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  datatable_search: 'اكتب للبحث...',
  username: 'اسم مستخدم الدخول',
  name: 'اسمك الكامل',
  mobile: 'رقم الجوال الخاص بك',
  tenant_mobile: 'رقم جوال المستأجر',
  reservation_user_id: 'المستخدم المسؤول عن الحجز',
  payment_user_id: 'المستخدم صاحب الدفعة',
  customer_no_data_text: 'لا يوجد عميل بهذه البيانات، قم بإضافة عميل جديد',
  organization_name: 'إسم الشركة أو الاسم التجاري',
  commercial_register: 'الرقم التجاري للمنشأة/الشركة/فرد',
  vat_number: 'الرقم الضريبي في دولتك',
  search_on_customer: 'البحث عن عميل بواسطة الاسم او رقم الجوال',
  payment_method_id_complete: 'طريقة استلام المبلغ المتبقي'
}
