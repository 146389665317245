/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  add_payment: 'إضافة دفعة',
  confirm: 'تأكيد الحجز',
  complete: 'إكمال الحجز',
  details: 'بيانات الحجز',
  payments: 'الدفعات',
  contract: 'عرض العقد',
  update_date: 'تعديل تاريخ الحجز',
  contract_signing: 'رابط توقيع العقد',
  contract_signing_for_apps: 'رابط توقيع عقد التطبيقات',
  link_copied: 'تم نسخ الرابط',
  open_whatsapp: 'فتح واتساب ويب',
  add_payment_reservation: 'اضافة دفعة/صرف',
  reservations_date: 'حجوزات تاريخ {date}'
}
