/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { StubUrl } from '../Stub'
import axios from 'axios'

const url = StubUrl('Reports')

export default {
  reservations: (params) => axios.get(url('Reservations'), { params }),
  payments: (params) => axios.get(url('Payments'), { params }),
  profitLoss: (params) => axios.get(url('ProfitLoss'), { params })
}
