/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

export default {
  panel: {
    user: {
      home: 'الرئيسية'
    },
    reports: {
      sales: 'تقرير المبيعات',
      expenses: 'تقرير المصروفات',
      'profit-loss': 'الأرباح والخسائر'
    },
    reservation: {
      calendar: 'جدولة الحجوزات'
    }
  }
}
