/*
 * MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
 * Email: mythpe@gmail.com
 * Mobile: +966590470092
 * Website: https://www.4myth.com
 */

import { APP_ROUTES } from '@routes/list'

export default [
  {
    path: '/AboutUs',
    name: APP_ROUTES.public.aboutUs,
    component: () => import(/* webpackChunkName: "AboutUsPage" */ '@pages/AboutUs')
  },
  {
    path: '/ContactUs',
    name: APP_ROUTES.public.contactUs,
    component: () => import(/* webpackChunkName: "ContactUsPage" */ '@pages/ContactUs')
  },
  {
    path: '/PrivacyPolicy',
    name: APP_ROUTES.public.privacyPolicy,
    component: () => import(/* webpackChunkName: "PrivacyPolicyPage" */ '@pages/PrivacyPolicy')
  },
  {
    path: '/TermsAndConditions',
    name: APP_ROUTES.public.termsAndConditions,
    component: () => import(/* webpackChunkName: "TermsAndConditionsPage" */ '@pages/TermsAndConditions')
  }
]
