<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card>
    <v-container
      fluid
    >
      <v-row>
        <app-date-picker
          v-model="form.from_date"
          :clearable="!1"
          cols="12"
          name="from_date"
          sm="6"
        />
        <app-date-picker
          v-model="form.to_date"
          :clearable="!1"
          cols="12"
          name="to_date"
          sm="6"
        />
        <!--<app-users
          v-model="form.user_id"
          clearable
          cols="12"
          name="user_id"
          sm="6"
        />
        <app-payment-categories
          v-model="form.payment_category_id"
          clearable
          cols="12"
          multiple
          name="payment_category_id"
          sm="6"
        />
        <app-payment-types
          v-model="form.payment_type"
          clearable
          cols="12"
          name="payment_type"
          sm="6"
        />
        <app-customers
          v-model="form.customer_id"
          clearable
          cols="12"
          item-text="name_and_mobile"
          name="customer_id"
          persistent-hint
          sm="6"
        />-->
      </v-row>
      <v-row>
        <v-col>
          <app-btn
            :loading="loading"
            @click="submit"
          >
            {{ $t('done') }}
          </app-btn>
        </v-col>
      </v-row>
    </v-container>
  </app-card>
</template>
<script>
import { MetaInfoMixin } from '@mixins'

export default {
  name: 'ProfitLoss',
  mixins: [MetaInfoMixin],
  data () {
    return {
      modelPermission: 'Reports',
      loading: !1,
      form: {
        from_date: this.$moment().locale('en').startOf('month').format('YYYY-MM-DD'),
        to_date: this.$moment().locale('en').endOf('month').format('YYYY-MM-DD')
      }
    }
  },
  mounted () {
    this.checkPermission('profitLoss')
  },
  methods: {
    async submit () {
      if (this.loading) return
      this.loading = !0
      try {
        const { _data } = await this.apiService.report.profitLoss(this.form)
        _data?.url && this.openWindow(_data.url)
        // console.log(_data)
      } catch ({ _message }) {
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
